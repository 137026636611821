// -----------------------------------------------------------------------------
// Transition mixins/functions
// -----------------------------------------------------------------------------

@mixin filter-content($filter-blur: 12px) {
  filter: blur($filter-blur) contrast(0.8) brightness(0.4);
  transition: filter 0.3s;
}

@mixin make-content-filterable {
  transition: filter 0.3s;

  &.--is-filtered {
    @include filter-content;
  }
}

@mixin transitioning {
  opacity: 0;

  &.--disable-fade-out {
    transition: unset !important;
  }
}

@mixin transitioned {
  opacity: 1;

  &.--disable-fade-in {
    transition: unset !important;
  }
}
