@use "@/sass/variables/breakpoints.module";
@use "@/sass/variables/colors.module";

// -----------------------------------------------------------------------------
// App wide mixins/functions
// -----------------------------------------------------------------------------

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

// grid-auto-columns: calc([slate-percentage-width] - [adjustment]);
// adjustment: [grid-gap] * ((100 - [slate-percentage-width]) / 100)
// Eg if slate percentage width is 33%, grid gap 24px
// adjustment is 24px * ((100 - 33) / 100) = 16.08px
// so grid-auto-columns: calc(33% - 16.08px)
@mixin show-row {
  display: grid;
  grid-auto-columns: calc(33.33% - 10.66px);
  grid-gap: 16px;
  width: 100%;
  padding: 0;
  list-style-type: none;

  @media only screen and (min-width: 0) and (max-width: 450px) {
    grid-auto-columns: calc(45% - 8.8px);

    &.--is-mobile {
      grid-auto-columns: 40%;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: breakpoints.$bp-width-px-mobile) {
    grid-auto-columns: calc(33.33% - 10.66px);

    &.--is-mobile {
      grid-auto-columns: 40%;
    }
  }

  @media only screen and (min-width: breakpoints.$bp-width-px-mobile-as-lower-bound) and (max-width: 900px) {
    grid-auto-columns: calc(25% - 12px);

    &.--is-mobile {
      grid-auto-columns: 21%;
    }
  }

  @media only screen and (min-width: 901px) and  (max-width: 1024px) {
    grid-auto-columns: calc(20% - 16px);
    grid-gap: 20px;

    &.--is-mobile {
      grid-auto-columns: 16.66%;
    }
  }

  // --is-mobile for screens bigger than 1024px:
  // grid-auto-columns: calc([slate-percentage-width] - [adjustment]);
  // adjustment: [grid-gap] * (([number-of-full-slates] + 1) / [number-of-full-slates])
  // Eg if slate percentage width is 33% (display 3 full slates), grid gap 24px
  // adjustment is 24px * (3.5 / 3) = 28px
  // so grid-auto-columns: calc(33% - 28px)

  @media only screen and (min-width: 1025px) and  (max-width: 1250px) {
    grid-auto-columns: calc(20% - 22.4px);
    grid-gap: 28px;

    &.--is-mobile {
      grid-auto-columns: calc(16.66% - 36.4px);
    }
  }

  @media only screen and (min-width: 1251px) and  (max-width: 1550px) {
    grid-auto-columns: calc(16.66% - 23.52px);
    grid-gap: 28px;

    &.--is-mobile {
      grid-auto-columns: calc(16.66% - 36.4px);
    }
  }

  @media only screen and (min-width: 1551px) and  (max-width: 2150px) {
    grid-auto-columns: calc(14.28% - 24px);
    grid-gap: 28px;

    &.--is-mobile {
      grid-auto-columns: calc(12.5% - 29.75px);
    }
  }

  @media only screen and (min-width: 2151px) {
    grid-auto-columns: calc(12.5% - 1.31%);
    grid-gap: 1.5%;

    &.--is-mobile {
      grid-auto-columns: calc(12.5% - 1.56%);
    }
  }
}

// grid-template-columns: repeat(auto-fill, calc([slate-percentage-width] - [adjustment]));
// adjustment: [grid-column-gap] * ([slate-count] - 1) / [slate-count]
// Eg if slate percentage width is 33%, grid-column-gap 16px
// adjustment is 16px * 2 / 3 = 10.66px
// so grid-template-columns: repeat(auto-fill, calc(33% - 10.66px))
@mixin show-grid {
  display: grid;
  grid-row-gap: 44px;
  grid-column-gap: 44px;
  grid-template-columns: repeat(auto-fill, calc(50% - 22px));
  width: 100%;
  padding: 0;
  list-style-type: none;

  @media only screen and (min-width: 551px) and (max-width: breakpoints.$bp-width-px-mobile) {
    grid-template-columns: repeat(auto-fill, calc(33.33% - 29.33px));
  }

  @media only screen and (min-width: breakpoints.$bp-width-px-mobile-as-lower-bound) and (max-width: 900px) {
    grid-row-gap: 50px;
    grid-column-gap: 30px;
    grid-template-columns: repeat(auto-fill, calc(25% - 22.5px));
  }

  @media only screen and (min-width: 901px) and  (max-width: 1250px) {
    grid-row-gap: 50px;
    grid-column-gap: 30px;
    grid-template-columns: repeat(auto-fill, calc(20% - 24px));
  }

  @media only screen and (min-width: 1251px) and  (max-width: 1750px) {
    grid-row-gap: 60px;
    grid-column-gap: 30px;
    grid-template-columns: repeat(auto-fill, calc(16.66% - 25px));
  }

  @media only screen and (min-width: 1751px) and  (max-width: 2150px) {
    grid-row-gap: 70px;
    grid-column-gap: 30px;
    grid-template-columns: repeat(auto-fill, calc(14.28% - 25.71px));
  }

  @media only screen and (min-width: 2151px) {
    grid-row-gap: 84px;
    grid-column-gap: 30px;
    grid-template-columns: repeat(auto-fill, calc(12.5% - 26.25px));
  }
}

@mixin track-list-grid {
  display: grid;
  grid-row-gap: 42px;
  grid-column-gap: 42px;

  @media only screen and (max-width: breakpoints.$bp-width-px-mobile) {
    grid-row-gap: 36px;
    grid-column-gap: 36px;
    grid-template-columns: repeat(auto-fill, calc(50% - 18px));
  }

  @media only screen and (min-width: breakpoints.$bp-width-px-mobile-as-lower-bound) and (max-width: 850px) {
    grid-template-columns: repeat(auto-fill, calc(33.3333% - 28px));
  }

  @media only screen and (min-width: 851px) and (max-width: 1150px) {
    grid-template-columns: repeat(auto-fill, calc(25% - 31.5px));
  }

  @media only screen and (min-width: 1151px) and (max-width: 1450px) {
    grid-template-columns: repeat(auto-fill, calc(20% - 33.6px));
  }

  @media only screen and (min-width: 1451px) and (max-width: 1850px) {
    grid-template-columns: repeat(auto-fill, calc(16.66% - 35px));
  }

  @media only screen and (min-width: 1851px) and (max-width: 2150px) {
    grid-template-columns: repeat(auto-fill, calc(14.28% - 36px));
  }

  @media only screen and (min-width: 2151px) and (max-width: 2350px) {
    grid-template-columns: repeat(auto-fill, calc(12.5% - 36.75px));
  }

  @media only screen and (min-width: 2351px) {
    grid-template-columns: repeat(auto-fill, calc(11.11% - 37.33px));
  }
}

@mixin hover-reduce-opacity($opacity: 0.7) {
  transition: opacity 0.2s ease-out;

  @include hover {
    opacity: $opacity;
    transition: opacity 0.2s ease-out;
  }
}

@mixin hover-highlight-background($color: colors.$color-black-light) {
  transition: background-color 0.2s ease-out;

  @include hover {
    background-color: $color;
    transition: background-color 0.2s ease-out;
  }
}

@mixin remove-scroll-bars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */

  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
}

@mixin custom-scroll-bar {
  scrollbar-color: rgb(133 134 143 / 55%) rgb(0 0 0 / 0%);

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 rgb(0 0 0 / 0%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: colors.$color-grey;
    border-radius: 5px;

    @include hover {
      background-color: rgb(133 134 143 / 55%);
    }
  }
}

@mixin hardware-acceleration {
  transform: translateZ(0);
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
