@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/hero";

.page-content-shifted-over-hero {
  position: relative;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: colors.$color-black;

  @include responsive-vw.support-vw-mobiles {
    top: -40px;
  }

  @include responsive-vw.support-minimum-vw-small-desktops {
    top: -40px;
  }

  @include hero.if-desktop-wide-aspect-ratio {
    position: absolute;
    top: calc(100vh - 240px);
  }

  @include hero.if-desktop-wide-aspect-ratio-but-small-height {
    position: absolute;
    top: calc(100vh - 40px);
  }
}
