@use "@/sass/abstracts/layout";
@use "@/sass/variables/colors.module";

.error-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px;

  .error-text {
    margin-bottom: 16px;
    color: colors.$color-white;

    &.--no-margin {
      margin-bottom: 0;
    }

    &.--more-margin {
      margin-bottom: 24px;
    }
  }
}

.error-retry-button {
  --svg-color: #{colors.$color-white};

  margin-right: auto;
  margin-left: auto;
  padding: 0 18px;
  color: colors.$color-white;
  background: colors.$color-black-light;

  svg {
    position: relative;
    top: -1px;
    left: 4px;
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
}
