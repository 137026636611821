@use "@/sass/abstracts/responsive-vw";

$wide-screen-aspect-ratio: "8/5";
$breakpoint-small-height: 800px;

@mixin if-desktop-wide-aspect-ratio {
  @include responsive-vw.support-minimum-vw-tablets {
    @media only screen and (min-height: $breakpoint-small-height) {
      @media only screen and (min-aspect-ratio: $wide-screen-aspect-ratio) {
        @content;
      }
    }
  }
}

@mixin if-desktop-wide-aspect-ratio-but-small-height {
  @include responsive-vw.support-minimum-vw-tablets {
    @media only screen and (max-height: $breakpoint-small-height) {
      @media only screen and (min-aspect-ratio: $wide-screen-aspect-ratio) {
        @content;
      }
    }
  }
}
