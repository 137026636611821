@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/variables/colors.module";

.episode-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: colors.$color-black;
  border-radius: 16px;

  .episode-menu-close {
    position: absolute;
    top: 34px;
    right: 34px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.episode-menu-option {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 54px;
  padding: 0 30px;
  color: colors.$color-white;
  cursor: pointer;
  transition: opacity 0.3s linear;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .icon-wrapper {
    position: relative;
    top: -1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: 32px;
    min-height: 32px;
    margin-right: 8px;
    cursor: pointer;

    svg {
      width: 16px;
    }
  }

  @include common.hover-highlight-background;

  &.--is-disabled {
    cursor: auto;
    opacity: 0.35;
    transition: opacity 0.3s linear;

    @include common.hover {
      background: unset;
    }
  }
}

.episode-menu-overlay-mobile-transition {
  transform: translateY(100%);
  transition: all 0.25s ease-out;

  &.--is-open {
    transform: translateY(0);
    transition: all 0.25s ease-out;
  }
}

.episode-menu-overlay-mobile {
  height: 100%;
  overflow-y: hidden !important;

  @include common.remove-scroll-bars;

  .overlay-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .background-slate-image {
      $blur-negative-margin: 20px;

      width: calc(100% + ($blur-negative-margin * 2));
      height: calc(100% + ($blur-negative-margin * 2));
      margin: -$blur-negative-margin;
      filter: blur(12px) brightness(0.65);
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;

    .show-info {
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 100%;

      :not(:last-child) {
        z-index: 1;
      }

      :last-child {
        z-index: 0; // Gradient
      }

      .top-section {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 100%;

        .show-square-image {
          width: clamp(0px, 50vw, 300px);
          border-radius: 16px;
          box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
          cursor: pointer;

          @include common.hover-reduce-opacity;
        }

        .episode-title {
          margin-top: 24px;
          padding: 0 24px;
          text-align: center;
          cursor: pointer;

          @include common.hover-reduce-opacity;
        }

        .channel-title {
          margin-top: 4px;
          color: colors.$color-grey-x-light;
          cursor: pointer;

          @include common.hover-reduce-opacity;
        }
      }

      .bottom-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding-top: 16px;
        background: colors.$color-black;

        .cancel-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 7.5vh;
          margin-bottom: 16px;
          cursor: pointer;

          @include common.hover-reduce-opacity;

          .clear-icon {
            position: relative;
            right: 1px;
            width: 20px;
            height: 20px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

.episode-menu-overlay-desktop {
  position: absolute;
  z-index: 55;
  display: flex;
  align-items: center;
  width: 317px;

  .episode-menu-option {
    padding-top: 15px;
    padding-bottom: 15px;

    &:first-child {
      padding-top: 20px;
      padding-bottom: 15px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    &:last-child {
      padding-top: 15px;
      padding-bottom: 20px;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }
}

.overlay-floating-track-wrapper {
  position: absolute;
  z-index: 65;
}
