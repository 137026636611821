@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/loading-states";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/font-sizes.module";

.profile-page-wrapper {
  display: flex;
  height: 100%;
}

.profile-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 500px;

  .profile-stats-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin-top: 16px;
    margin-bottom: 48px;

    @include responsive-vw.support-vw-mobiles {
      flex-direction: column;
      align-items: center;

      .stat {
        align-items: center !important;
        text-align: center !important;

        &:not(:last-child) {
          margin-bottom: 20px;

          &.--is-loading {
            margin-bottom: 35px;
          }
        }
      }
    }

    @include responsive-vw.support-vw-medium-desktops {
      max-width: 700px;
    }

    @include responsive-vw.support-vw-large-desktops {
      max-width: 800px;
    }
  }

  .profile-account-features-list {
    width: 100%;
    max-width: 500px;
    margin-bottom: 32px;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .profile-image {
    width: 46px;
    height: 46px;
    margin: 0 0 16px;
  }

  .profile-name {
    text-align: center;

    &.--is-loading {
      width: clamp(100px, 20%, 200px);
    }
  }
}
