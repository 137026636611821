// -----------------------------------------------------------------------------
// Font faces
// -----------------------------------------------------------------------------

@font-face {
  font-weight: 400;
  font-family: "Maison Neue";
  font-style: normal;
  src:
    // url("/static/fonts/MaisonNeue-Book.woff2") format("woff2"), // This font is currently not in the static folder
    url("/static/fonts/MaisonNeue-Book.woff") format("woff"),
    url("/static/fonts/MaisonNeue-Book.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-family: "Maison Neue";
  font-style: normal;
  src:
    url("/static/fonts/MaisonNeue-Medium.woff2") format("woff2"),
    url("/static/fonts/MaisonNeue-Medium.woff") format("woff"),
    url("/static/fonts/MaisonNeue-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: "Maison Neue";
  font-style: normal;
  src:
    url("/static/fonts/MaisonNeue-Bold.woff2") format("woff2"),
    url("/static/fonts/MaisonNeue-Bold.woff") format("woff"),
    url("/static/fonts/MaisonNeue-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: "Roc Grotesk";
  font-style: normal;
  src:
    url("/static/fonts/RocGrotesk-Bold.woff2") format("woff2"),
    url("/static/fonts/RocGrotesk-Bold.woff") format("woff"),
    url("/static/fonts/RocGrotesk-Bold.otf") format("opentype");
  font-display: swap;
}
