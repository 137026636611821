.sortable-list-container-wrapper {
  position: relative;
  width: 100%;
}

.sortable-list-container {
  width: 100%;
}

.sortable-list-item {
  width: 100%;
}

.sortable-helper {
  z-index: 1000;
}
