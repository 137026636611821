@use "@/sass/abstracts/transitions";

// -----------------------------------------------------------------------------
// Fading transition styles
// -----------------------------------------------------------------------------

.--is-transitioning {
  transition: opacity 0.5s;

  @include transitions.transitioning;
}

.--is-transitioned {
  @include transitions.transitioned;

  transition: opacity 0.5s;
}

.--is-transitioning-03 {
  @include transitions.transitioning;

  transition: opacity 0.3s;
}

.--is-transitioned-03 {
  @include transitions.transitioned;

  transition: opacity 0.3s;
}

.--is-transitioning-05 {
  @include transitions.transitioning;

  transition: opacity 0.5s;
}

.--is-transitioned-05 {
  @include transitions.transitioned;

  transition: opacity 0.5s;
}

.--is-transitioning-1 {
  @include transitions.transitioning;

  transition: opacity 1s;
}

.--is-transitioned-1 {
  @include transitions.transitioned;

  transition: opacity 1s;
}

// CSS Transition Groups
// Transition opacity 0.2s
.transition-opacity-enter {
  @include transitions.transitioning;

  transition: opacity 0.2s;
}

.transition-opacity-enter-active {
  @include transitions.transitioned;

  transition: opacity 0.2s;
}

.transition-opacity-exit {
  @include transitions.transitioned;
}

.transition-opacity-exit-active {
  @include transitions.transitioning;

  transition: opacity 0.2s;
}

// Transition opacity 0.5s
.transition-opacity-05s-enter {
  @include transitions.transitioning;
}

.transition-opacity-05s-enter-active {
  @include transitions.transitioned;

  transition: opacity 1s;
}

.transition-opacity-05s-exit {
  @include transitions.transitioned;
}

.transition-opacity-05s-exit-active {
  @include transitions.transitioning;

  transition: opacity 0.5s;
}
