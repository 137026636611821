@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/loading-states";
@use "@/sass/variables/colors.module";
@use "@/sass/variables/font-sizes.module";

.stat {
  display: flex;
  flex-direction: column;
  width: 50%;
  white-space: pre-line;

  &.--is-full-width {
    width: 100%;
  }

  &.--is-aligned-center {
    align-items: center;
  }

  &.--is-aligned-right {
    align-items: flex-end;
  }

  .stat-title {
    color: colors.$color-grey-x-light;
  }

  .stat-value {
    min-height: 16px;
    color: colors.$color-white;
  }
}
