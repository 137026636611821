@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/variables/font-sizes.module" as fs;
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";

.mob__library-page {
  @include responsive-vw.only-display-for-mobile-widths;

  flex: 1 !important;
}

.library-reset-filters-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  color: colors.$color-blue;
  cursor: pointer;

  --svg-color: #{colors.$color-blue};

  @include common.hover-reduce-opacity;

  svg {
    position: relative;
    top: 3px;
    left: 2px;
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
}

.library-filters-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;

  .library-filter-selector {
    &:first-child {
      margin-right: 16px;

      .selector {
        padding-right: 0;
        padding-left: 0;
      }
    }

    &:last-child {
      margin-left: 16px;

      .selector {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  @include responsive-vw.support-vw-small-mobiles {
    flex-direction: column;

    .library-filter-selector {
      margin: 0 !important;

      .selector {
        justify-content: center;
      }
    }
  }
}

.reset-button-mobile-wrapper {
  margin-top: 16px;
}

.reset-button.desktop-mobile {
  @include fonts.font-size-with-loading(60, "fixed");

  width: 42px;
  max-width: 42px;
  min-height: 42px;
  padding: 0 16px;
  color: colors.$color-blue;
  background: none;
  border: 2px solid colors.$color-blue;
  border-radius: 50%;
  opacity: 1;
  transition: all 0.3s;

  svg {
    --svg-color: #{colors.$color-blue};

    position: relative;
    right: 8px;
    bottom: 1px;
    width: 18px;
    height: 18px;
  }

  &.--is-hidden {
    opacity: 0;
    transition: all 0.3s;
  }

  @include responsive-vw.support-vw-mobiles {
    width: auto;
    margin-bottom: 12px;
    border-radius: 50px;
  }
}

.library-search-wrapper {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
}

.library-search-wrapper-desktop {
  height: 42px;
  margin-left: 16px;

  @include responsive-vw.support-vw-tablets {
    max-width: 200px;
  }
}

.library-episode-preview-large-list-wrapper {
  width: 100%;
  margin-top: 10px;
}

.search-state-indicator-library {
  margin-bottom: 5%;
}

// DESKTOP
.dskt__library-page {
  @include responsive-vw.only-display-for-non-mobile-widths;

  flex: 1 !important;
}

.dskt__library-page-contents {
  flex: 1 !important;
}

.library-desktop-heading-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .title {
    margin-bottom: 72px;
    font-weight: 700;

    &.--no-margin {
      margin: 0;
    }
  }

  @include responsive-vw.support-maximum-vw-tablets {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .title {
      margin-bottom: 48px;
    }
  }
}

.library-desktop-filters-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;

  .filter-dropdowns {
    display: flex;
    flex-direction: row;
    width: calc(60% + 80px);

    .drop-down {
      min-width: 150px;
      max-width: 300px;
      margin-right: 40px;

      &.format-drop {
        min-width: 120px;
      }

      @include responsive-vw.support-vw-tablets {
        margin-right: 20px;
      }
    }
  }
}

.desktop-library-stats {
  position: relative;
  top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  @include responsive-vw.support-maximum-vw-tablets {
    right: unset;
    justify-content: flex-start;
  }

  .stat {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    height: 100%;
    margin-left: 32px;

    @include responsive-vw.support-maximum-vw-tablets {
      align-items: flex-start;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.format-filter-wrapper {
  display: grid;
  grid-gap: 32px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  padding: 32px 16px;

  @include responsive-vw.support-vw-small-mobiles {
    padding: 16px 0;
  }

  .format-filter {
    display: flex;
    flex-direction: column;

    @include common.hover-highlight-background;

    align-items: center;
    justify-content: center;
    height: 25vh;
    border: 1px solid rgb(255 255 255 / 30%);
    cursor: pointer;

    &.--is-selected {
      color: colors.$color-blue;
      border: 1px solid rgb(0 122 255 / 30%);
    }

    &.--is-top {
      align-self: flex-end;
    }

    @include responsive-vw.support-vw-small-mobiles {
      height: 15vh;
    }
  }
}

.library-episodes-results {
  display: flex;
  flex: 1 !important;
  flex-direction: column;
  justify-content: center;
}

.library-no-results-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 5%;

  .library-empty-text {
    margin-bottom: 8px;
    color: colors.$color-grey-x-light;
  }
}
