@use "sass:map";
@use "@/sass/abstracts/fonts";
@use "@/sass/variables/fonts.module" as font-variables;
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/loading-states";
@use "@/sass/variables/font-sizes.module" as fs;
@use "@/sass/vendors/fonts" as vendors-fonts;

// -----------------------------------------------------------------------------
// App typography
// -----------------------------------------------------------------------------

@mixin loading-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

html {
  @include vendors-fonts.responsive-fonts-mixin(fs.$font-scaling-ratio-per-vw, 11px, 12px);

  @include responsive-vw.support-minimum-vw-x-large-desktops {
    @include vendors-fonts.responsive-fonts-mixin(fs.$font-scaling-ratio-per-vw, 11px, 14px);
  }

  @include responsive-vw.support-minimum-vw-xx-large-desktops {
    @include vendors-fonts.responsive-fonts-mixin(fs.$font-scaling-ratio-per-vw, 11px, 16px);
  }
}

body {
  @include fonts.font-size(60);

  color: colors.$color-white;
  font-weight: 500;
  font-family: font-variables.$font-stack-maison-neue;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.text {
  display: inline-block;
  backface-visibility: hidden;
  user-select: none;
  -webkit-font-smoothing: antialiased;

  &.--is-selectable {
    user-select: text;
  }

  &.--is-bold {
    font-weight: 700;
  }

  &.--prevent-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.--use-header-font {
    font-family: font-variables.$font-stack-roc;
  }
}

@each $key, $fs-key in fs.$fs-keys {
  .text-#{$fs-key} {
    @include fonts.font-size($fs-key);

    &.--is-fixed {
      @include fonts.font-size($fs-key, "fixed");
    }

    @for $i from 1 through 10 {
      &.--is-wrapped-#{$i} {
        @include fonts.wrap-lines($fs-key, $lines: $i);

        &.--is-fixed {
          @include fonts.wrap-lines($fs-key, "fixed", $lines: $i);
        }
      }
    }
  }
}

.text-loading-wrapper {
  @include loading-wrapper;
}

@each $key, $fs-key in fs.$fs-keys {
  .text-loading-wrapper-#{$fs-key} {
    @include fonts.font-size-with-loading($fs-key);

    &.--is-fixed {
      @include fonts.font-size-with-loading($fs-key, "fixed");
    }
  }
}
