@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/variables/colors.module";

$header-top-height: 80px;
$header-bottom-height: 120px;

.filter-menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.filter-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background: colors.$color-black;

  .header-footer-section {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    background: colors.$color-black;

    &:first-child {
      top: 0;
      width: 100%;
      border-bottom: 1px solid rgb(255 255 255 / 20%);
    }

    &:last-child {
      width: 100%;
      height: 120px;
    }
  }

  .scrolling-content-wrapper {
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
    min-height: 0;

    .scrolling-content {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: calc(100% - $header-top-height);
      margin: auto;
      margin-top: 80px;
      overflow-y: auto;

      @include common.remove-scroll-bars;

      .list-options {
        padding-top: $header-top-height;
        padding-bottom: calc($header-bottom-height + 15%);

        .scroller-option {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          min-height: 50px;
          color: colors.$color-grey-x-light;
          cursor: pointer;

          @include common.hover-highlight-background;

          &.--is-selected {
            color: colors.$color-white;
            font-weight: bold;
            font-size: 2.5rem;
          }
        }
      }
    }
  }

  .content-wrapper {
    width: 100%;
    height: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
