@use "@/sass/abstracts/responsive-vw";

.mob__discover-page {
  width: 100%;

  @include responsive-vw.only-display-for-mobile-widths;
}

.mob__discover-top-banner {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  @include responsive-vw.only-display-for-mobile-widths;
}
