@use "@/sass/abstracts/layout";

@mixin loader-overlay {
  pointer-events: none;
  touch-action: none;
}

.loader-overlay-background {
  @include loader-overlay;
}

.loader-overlay-content {
  @include loader-overlay;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loader,
.loader::after {
  width: 10em;
  height: 10em;
  border-radius: 50%;
}

.loader {
  position: relative;
  margin: 60px auto;
  text-indent: -9999em;
  border-top: 1.1em solid rgb(255 255 255 / 20%);
  border-right: 1.1em solid rgb(255 255 255 / 20%);
  border-bottom: 1.1em solid rgb(255 255 255 / 20%);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
