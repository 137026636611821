@use "@/sass/abstracts/common";
@use "@/sass/variables/colors.module";

.slate-overlay {
  position: absolute;
  top: -1.5%;
  left: -1.5%;
  z-index: 10;
  width: 103%;
  height: 103%;
  background: colors.$color-black;
  opacity: 0;
  transition: opacity 0.3s;

  &.--is-active {
    opacity: 0.75;
  }
}

.slate-overlay__content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;

  &.--justify-end {
    justify-content: flex-end;
  }

  &.--is-active {
    cursor: pointer;
    opacity: 1;
  }
}

.slate-overlay__info-icon-wrapper {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px;
  transition: opacity 0.2s linear;

  @include common.hover {
    opacity: 0.6;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
