@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/layout";
@use "@/sass/variables/layout-dimensions.module";
@use "@/sass/abstracts/fonts";
@use "@/sass/variables/font-sizes.module";
@use "@/sass/abstracts/loading-states";
@use "./GenreInfoDesktop";

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

.dskt__genre-page {
  @include responsive-vw.only-display-for-non-mobile-widths;
}

.dskt__genre-selector-wrapper {
  width: 100%;
}

.dskt__genre-selector {
  position: relative;
  bottom: -1px;
  margin-top: 8px;
}

.dskt__genre-separator {
  width: 100%;
  height: 1px;
  background: colors.$color-grey;
}

.dskt__genre-content {
  padding-top: clamp(24px, 2vw, 64px);
}

.dskt__genre-shows-grid-wrapper {
  margin-top: clamp(30px, 3vw, 100px);
}
