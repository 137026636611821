@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "./GenreInfoMobile";
@use "./GenreHeaderMobile";

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

$left-padding: 64px;

// EXPORT TO JAVASCRIPT
:export {
  var-genre-scroller-left-padding: $left-padding;
}

.mob__genre-page {
  width: 100%;

  @include responsive-vw.only-display-for-mobile-widths;
}

.mob__genre-separator {
  width: 100%;
  height: 1px;
  background: colors.$color-grey;
}

.mob__genre-shows-grid-wrapper {
  margin-top: clamp(30px, 7.5vw, 64px);
}
