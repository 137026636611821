@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/variables/colors.module";

.notification {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0 16px 16px;
  pointer-events: none;
  touch-action: none;
}

.mobile-notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 500px;
  min-height: 80px;
  padding: 8px 16px 8px 8px;
  background: rgb(21 21 21 / 100%);
  border-bottom: 1px solid colors.$color-grey;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 75%);
  transform: translateY(-100%);
  cursor: pointer;
  opacity: 1;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease-out;
  pointer-events: all;
  touch-action: auto;

  @include common.hover-reduce-opacity;

  .episode-image {
    min-width: 64px;
    height: 64px;
    border-radius: 4px;
  }

  img {
    width: 64px;
    height: 64px;
  }

  .episode-information {
    position: relative;
    top: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 16px;

    .episode-name {
      color: colors.$color-white;
    }

    .action-text {
      color: colors.$color-grey-x-light;

      .notification-destination-text {
        &.--is-highlighted {
          color: colors.$color-blue;
        }
      }
    }

    &.small-notification-text {
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0;
      text-align: center;

      .action-text {
        position: relative;
        top: -1px;
        align-items: center;
      }
    }
  }

  &.--is-active {
    transition: all 0.3s ease-in;
  }

  &.--is-finished {
    transition: all 0.3s ease-out;
  }
}
