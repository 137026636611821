@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "@/sass/variables/layout-dimensions.module";

// -----------------------------------------------------------------------------
// Layout styles
// -----------------------------------------------------------------------------

.layout-wrapper-horizontal-disabled {
  width: 100%;
}

.layout-wrapper-horizontal {
  @include layout.layout-wrapper-horizontal;

  @include responsive-vw.support-vw-tablets {
    @include layout.layout-wrapper-horizontal-tablet;
  }

  @include responsive-vw.support-vw-mobiles {
    @include layout.layout-wrapper-horizontal-mobile;
  }

  &:not(.--disable-small-mobile-mode) {
    @include responsive-vw.support-vw-small-mobiles {
      @include layout.layout-wrapper-horizontal-small-mobile;
    }
  }
}

.layout-wrapper-horizontal-mobile {
  @include layout.layout-wrapper-horizontal-mobile;

  &:not(.--disable-small-mobile-mode) {
    @include responsive-vw.support-vw-small-mobiles {
      @include layout.layout-wrapper-horizontal-small-mobile;
    }
  }
}

.layout-wrapper-vertical {
  @include layout.layout-wrapper-vertical;

  @include responsive-vw.support-minimum-vw-tablets {
    padding-top: layout-dimensions.$header-height-px-desktop;
    padding-bottom: layout-dimensions.$footer-height-px-desktop;

    &.--extra-page-padding {
      padding-top: layout-dimensions.$page-padding-top-px-desktop;
      padding-bottom: layout-dimensions.$page-padding-bottom-px-desktop;
    }

    &.--is-disabled-top-desktop {
      padding-top: 0;
    }

    &.--is-disabled-bottom-desktop {
      padding-bottom: 0;
    }
  }

  @include responsive-vw.support-vw-mobiles {
    padding-top: layout-dimensions.$header-height-px-mobile;
    padding-bottom: layout-dimensions.$footer-height-px-mobile;

    &.--extra-page-padding {
      padding-top: layout-dimensions.$page-padding-top-px-mobile;
      padding-bottom: layout-dimensions.$page-padding-bottom-px-mobile;
    }

    &.--is-disabled-top-mobile {
      padding-top: 0;
    }

    &.--is-disabled-bottom-mobile {
      padding-bottom: 0;
    }
  }

  &.--is-disabled {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.mob__header-bar {
  &:not(.--display-on-desktop) {
    @include responsive-vw.only-display-for-mobile-widths;
  }

  position: fixed;
  height: 80px;
  font-weight: 700;
  transition: all 0.2s ease-out;

  &.--is-active {
    background: colors.$color-black;
    transition: all 0.2s ease-out;
  }

  &:not(.--is-active) {
    background: none !important;
  }
}

.channel-header-title {
  text-align: center;
  opacity: 0;
  transition: all 0.2s ease-out;

  &.--is-active {
    opacity: 1;
  }
}

.--offscreen-hide {
  * {
    transition: none !important;
  }
}

.--onscreen-show {
  // Any styles that should be applied as part of intersection observer
}
