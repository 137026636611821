@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/variables/font-sizes.module" as fs;
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/loading-states";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";

.button-cta {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 140px;
  min-height: 56px;
  padding: 0 25px;
  color: colors.$color-black;
  font-weight: 700;
  background: colors.$color-white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: opacity 0.3s;
  pointer-events: auto;
  touch-action: auto;

  &.--is-rectangular {
    min-height: 40px;
    border-radius: 6px;
  }

  &.--is-disabled {
    cursor: auto;
    opacity: 0.55;
    transition: opacity 0.3s;
  }

  &.--is-loading {
    cursor: auto;

    @include loading-states.loading-gradient-background-horizontal;
  }

  &:not(.--is-disabled) {
    &:not(.--is-loading) {
      @include common.hover-reduce-opacity;
    }
  }
}

.button-cta-contents {
  position: relative;
  top: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  @include responsive-vw.support-vw-small-mobiles {
    top: 0;
    white-space: pre-wrap;
  }
}
