@use "@/sass/variables/colors.module";
@use "@/sass/variables/font-sizes.module";

// -----------------------------------------------------------------------------
// Loading state mixins/functions
// -----------------------------------------------------------------------------

@mixin loading-gradient-background-vertical($type: "opaque") {
  background: linear-gradient(180deg, colors.$color-grey -3.45%, colors.$color-black-light 104.73%);
  transition: opacity 0.3s;

  &.--is-loading {
    opacity: 0.55;
  }

  @if $type == "loading" {
    opacity: 0.55;
  }
}

@mixin loading-gradient-background-horizontal($type: "opaque") {
  background: linear-gradient(270deg, colors.$color-grey -3.45%, colors.$color-black-light 104.73%);
  transition: opacity 0.3s;

  &.--is-loading {
    opacity: 0.55;
  }

  @if $type == "loading" {
    opacity: 0.55;
  }
}
