@use "sass:map";
@use "@/sass/variables/font-sizes.module" as fs;
@use "@/sass/abstracts/loading-states";
@use "@/sass/vendors/fonts";

// -----------------------------------------------------------------------------
// Font mixins/functions
// -----------------------------------------------------------------------------

@mixin font-size($size, $type:null, $unit: "rem") {
  @if $type == "fixed" {
    font-size: #{map.get(fs.$font-sizes-px-fixed, $size)};
    line-height: #{map.get(fs.$line-heights-px-fixed, $size)};
  } @else {
    font-size: #{map.get(fs.$font-sizes-responsive, $size)}#{$unit};
    line-height: #{map.get(fs.$line-heights-responsive, $size)}#{$unit};
  }
}

@mixin wrap-lines($size, $type:null, $unit: "rem", $lines: 1) {
  display: -webkit-box;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: #{$lines};

  @if $type == "fixed" {
    max-height: calc(#{map.get(fs.$line-heights-px-fixed, $size)} * #{$lines});
  } @else {
    max-height: calc(#{map.get(fs.$line-heights-responsive, $size)}#{$unit} * #{$lines});
  }
}

@mixin set-height-to-match-font($size, $type:null, $unit: "rem") {
  @if $type == "fixed" {
    height: #{map.get(fs.$line-heights-px-fixed, $size)};
  } @else {
    height: #{map.get(fs.$line-heights-responsive, $size)}#{$unit};
  }
}

@mixin font-size-with-loading($size, $type:null, $unit: "rem") {
  @include font-size($size, $type, $unit);

  &.text-loading-wrapper {
    @include set-height-to-match-font($size, $type, $unit);
  }

  .text {
    &.--is-loading {
      width: 100%;
      min-width: #{map.get(fs.$text-loading-min-width, $size)};
      height: 75%;
      border-radius: 17px;

      @include loading-states.loading-gradient-background-horizontal;
    }
  }
}
