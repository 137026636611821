@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/normalise";
@use "@/sass/variables/colors.module";

@mixin overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: none;
  background: none;

  @include normalise.prevent-overflow;
}

.overlayBackground {
  @include overlay;

  background: colors.$color-black;
}

.overlay {
  @include overlay;
}
