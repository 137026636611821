// -----------------------------------------------------------------------------
// Normalisation mixins/functions
// -----------------------------------------------------------------------------

@mixin prevent-select-highlight {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

@mixin prevent-overflow {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

@mixin prevent-refresh {
  overscroll-behavior-y: none;
  touch-action: none;
}
