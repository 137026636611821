@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/variables/font-sizes.module" as fs;
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";

.selector-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    color: colors.$color-grey-x-light;
  }
}

.selector {
  display: flex;
  flex-direction: row;
  padding: 8px;
  cursor: pointer;

  .value {
    text-align: center;

    @include responsive-vw.support-vw-small-mobiles {
      @include fonts.font-size-with-loading(60);
    }
  }

  .arrow {
    position: relative;
    top: 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    margin-left: 12px;
    border-style: solid;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);

    @include responsive-vw.support-vw-small-mobiles {
      top: 5px;
      width: 7px;
      height: 7px;
      margin-left: 12px;
    }
  }

  &.--is-disabled {
    cursor: auto;
    opacity: 0.55;
  }

  &:not(.--is-disabled) {
    @include common.hover-reduce-opacity;
  }
}
