@use "@/sass/abstracts/responsive-vw";

.dskt__discover-page {
  width: 100%;

  @include responsive-vw.only-display-for-non-mobile-widths;
}

.dskt__discover-top-banner {
  @include responsive-vw.only-display-for-non-mobile-widths;
}

.dskt__discover-slate-info-non-tablet {
  margin-bottom: 52px;

  @include responsive-vw.support-maximum-vw-tablets {
    display: none;
  }
}

.dskt__discover-slate-info-tablet {
  margin-bottom: 52px;

  @include responsive-vw.support-minimum-vw-small-desktops {
    display: none;
  }
}
