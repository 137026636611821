@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/abstracts/transitions";
@use "@/sass/variables/colors.module";

@mixin toggle-player-bar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  background: colors.$color-black-matte;
  border-top: 1px solid colors.$color-grey;
  transform: translateY(0);
  transition: transform 0.3s linear, opacity 0.5s linear;
  will-change: transform, opacity;

  @include transitions.make-content-filterable;

  &.--is-hidden {
    padding: 0;
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
    touch-action: none;
  }
}

@mixin is-faded {
  opacity: 1;
  transition: opacity 0.2s ease-out;

  &.--is-faded {
    opacity: 0.5;
  }
}

@mixin side-section() {
  @include responsive-vw.support-vw-tablets {
    width: 25%;
  }

  @include responsive-vw.support-vw-small-desktops {
    width: 25%;
  }

  @include responsive-vw.support-vw-medium-desktops {
    width: 25%;
  }

  @include responsive-vw.support-vw-large-desktops {
    width: 20%;
  }
}

.player-bar-desktop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
  background: colors.$color-black-matte;
  opacity: 1;

  @include toggle-player-bar;

  .track-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s;

    @include side-section;

    .player-bar-image-wrapper {
      z-index: 3;
      width: 68px;

      @include common.hover-reduce-opacity;

      @include responsive-vw.support-vw-tablets {
        width: 56px;
      }
    }

    &.--is-visible {
      opacity: 1;
    }

    @include responsive-vw.support-maximum-vw-small-desktops {
      .player-bar-image-wrapper {
        display: none;
        width: 0;
      }

      .player-bar-current-track-info {
        margin-left: -16px;
      }
    }
  }

  .share-button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-left: 20px;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
    }

    &.--is-disabled {
      cursor: auto;
      opacity: 0.5;
      pointer-events: none;
    }

    &:not(.--is-disabled) {
      @include common.hover-reduce-opacity;
    }
  }

  .library-button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-left: 18px;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
    }

    &.tick {
      svg {
        width: 18px;
        height: 18px;
      }
    }

    &.--is-disabled {
      cursor: auto;
      opacity: 0.5;
      pointer-events: none;
    }

    &:not(.--is-disabled) {
      @include common.hover-reduce-opacity;
    }
  }

  .controls-wrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 13px;

    .controls {
      justify-content: center;
    }

    .minus-15 {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 26px;

      --svg-color: #{colors.$color-white};

      @include common.hover-reduce-opacity;

      @include responsive-vw.support-vw-tablets {
        height: 24px;
      }
    }

    .previous {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 18px;
      margin-left: 30px;
      opacity: 1;
      transition: opacity 0.2s;

      @include responsive-vw.support-vw-tablets {
        height: 14px;
        margin-left: 20px;
      }

      &.--is-disabled {
        cursor: auto;
        opacity: 0.5;
        pointer-events: none;
      }

      &:not(.--is-disabled) {
        @include common.hover-reduce-opacity;
      }
    }

    .play {
      position: relative;
      left: 2px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 26px;
      margin: 0 40px;
      transition: opacity 0.2s;

      @include common.hover-reduce-opacity;

      &.--is-disabled {
        cursor: auto;
        opacity: 0.5 !important;
        pointer-events: none;
      }

      @include responsive-vw.support-vw-tablets {
        left: 2px;
        height: 24px;
        margin: 0 28px;
      }
    }

    .next {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 18px;
      margin-right: 30px;
      opacity: 1;
      transition: opacity 0.2s;

      @include common.hover-reduce-opacity;

      @include responsive-vw.support-vw-tablets {
        height: 16px;
        margin-right: 20px;
      }

      &.--is-disabled {
        cursor: auto;
        opacity: 0.5;
        pointer-events: none;
      }

      &:not(.--is-disabled) {
        @include common.hover-reduce-opacity;
      }
    }

    .plus-15 {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 26px;

      --svg-color: #{colors.$color-white};

      @include common.hover-reduce-opacity;

      @include responsive-vw.support-vw-tablets {
        height: 24px;
      }
    }
  }

  .sub-controls-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    @include side-section;

    * {
      transform: translateZ(0);
    }

    .airplay-wrapper {
      position: relative;
      top: 1px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 40px;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.5s;

      &.--is-hidden {
        cursor: auto;
        opacity: 0;
        pointer-events: none;
        touch-action: none;
      }
    }

    .airplay-icon {
      width: 16px;
      height: 100%;

      @include is-faded;
    }

    .queue-wrapper {
      position: relative;
      right: -7px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 40px;
      cursor: pointer;
    }

    .queue-icon {
      width: 16px;

      &.--is-open {
        --svg-color: #{colors.$color-blue};
      }

      @include is-faded;
    }
  }
}

.track-listing-desktop {
  position: absolute;
  top: unset;
  right: 0;
  bottom: 0;
  left: unset;
  margin-bottom: 16px;
}

.track-listing-desktop-content {
  pointer-events: none;
  touch-action: none;
}

.currently-playing-track-wrapper {
  justify-content: flex-end;
}

.currently-playing-track-desktop {
  min-width: 270px;
  max-width: 360px;
  min-height: 60px;
}

.desktop-progress-bar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1400px;
  padding-top: 6px;

  .desktop-progress-bar {
    flex: 0.8;
  }

  .time {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 40px;
    color: colors.$color-grey-x-light;
  }
}

.desktop-slider-progress {
  background: colors.$color-blue !important;
}

.player-bar-current-track-info {
  position: relative;
  top: -1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: colors.$color-white;

  .episode-title {
    overflow: visible;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;

    &:not(.--disable-clicks) {
      cursor: pointer;

      @include common.hover {
        text-decoration: underline;
      }
    }
  }

  .channel-title {
    padding-top: 2px;
    overflow: visible;
    color: colors.$color-grey-x-light;
    white-space: nowrap;

    &:not(.--disable-clicks) {
      cursor: pointer;

      @include common.hover {
        text-decoration: underline;
      }
    }
  }
}
