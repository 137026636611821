@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/loading-states";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "@/sass/variables/fonts.module" as font-variables;

// header options
.desktop-header-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .desktop-header-option {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.2s;
    pointer-events: auto;
    touch-action: auto;

    &:not(.--disable-hover) {
      @include common.hover-reduce-opacity;
    }

    .active-line {
      position: absolute;
      right: 0;
      bottom: -5px;
      left: 0;
      width: 75%;
      margin: auto;
      opacity: 0;
      transition: all 0.2s;

      &.--is-active {
        border-bottom: 1px solid colors.$color-white;
        opacity: 1;
      }
    }

    .caret-down {
      width: 12px;
      height: 12px;
      margin-top: -2px;
      margin-left: 10px;
      transform: rotate(90deg);
      transition: all 0.2s;
    }

    &.--is-active {
      color: colors.$color-white;
      transition: all 0.2s;

      .caret-down {
        transition: all 0.2s;

        --svg-color: #{colors.$color-white};
      }
    }

    &.--is-open {
      color: colors.$color-white;
      transition: all 0.2s;

      .caret-down {
        transform: rotate(270deg);
        transition: all 0.2s;
      }
    }

    &:not(:last-child) {
      margin-right: clamp(40px, 5vw, 55px);
    }
  }

  // search
  .search-icon-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2px;

    --svg-color: #{colors.$color-white};

    svg {
      width: 18px;
      height: 18px;
    }
  }

  // drop down
  .header-option-more {
    position: relative;
    pointer-events: none;

    &.--is-active {
      pointer-events: auto;
    }
  }

  .more-options-icon-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2px;
    transform: rotate(90deg);

    --svg-color: #{colors.$color-white};

    @include common.hover-reduce-opacity;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .more-options-drop-down {
    position: absolute;
    top: 44px;
    right: 0;
  }

  // login
  .login-button {
    min-width: 110px;
    height: 42px;
    min-height: 42px;
    margin-left: 12px;
    cursor: pointer;
  }
}

// genres dropdown
.desktop-genres-overlay {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: font-variables.$font-stack-roc;

  .genres-list {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 12px 16px;
    grid-template-rows: repeat(var(--genre-rows-count-for-4-columns), 1fr);
    width: 100%;
    height: 100%;
    margin-left: 128px;

    @media only screen and (max-width: 1600px) {
      grid-template-rows: repeat(var(--genre-rows-count-for-4-columns), 1fr);
    }

    @media only screen and (max-width: 1350px) {
      grid-template-rows: repeat(var(--genre-rows-count-for-3-columns), 1fr);
    }

    @media only screen and (max-width: 1150px) {
      grid-template-rows: repeat(var(--genre-rows-count-for-2-columns), 1fr);
    }

    @media only screen and (max-width: 780px) {
      margin-left: 72px;
    }
  }

  .genre-title {
    @media only screen and (max-width: 780px) {
      display: none;
    }
  }

  .genre {
    @include common.hover-reduce-opacity;

    display: flex;
    align-items: center;
    cursor: pointer;

    &.--is-selected {
      color: colors.$color-blue;
    }

    .genre-text {
      width: max-content;
    }

    .new-badge {
      margin-left: 12px;
    }
  }

  .--is-loading {
    width: 44%;
    cursor: none;
    pointer-events: none;
  }
}
