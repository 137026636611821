@use "@/sass/variables/colors.module";

// -----------------------------------------------------------------------------
// Nprogress loader styles
// -----------------------------------------------------------------------------

$loader-colour: colors.$color-blue;

/* Make clicks pass-through */
#nprogress,
#nprogress-custom {
  transition: opacity 0.5s;
  pointer-events: none;
}

#nprogress .bar,
#nprogress-custom .bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1031;
  width: 100%;
  height: 2px;
  background: $loader-colour;
}

/* Fancy blur effect */
#nprogress .peg,
#nprogress-custom .peg {
  position: absolute;
  right: 0;
  display: block;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $loader-colour, 0 0 5px $loader-colour;
  transform: rotate(3deg) translate(0, -4px);
  opacity: 1;
}

/* Remove these to get rid of the spinner */
#nprogress .spinner,
#nprogress-custom .spinner {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1031;
  display: block;

  &.--is-centered {
    top: calc(50% - 9px);
    right: calc(50% - 9px);
    bottom: calc(50% - 9px);
    left: calc(50% - 9px);
  }
}

#nprogress .spinner-icon,
#nprogress-custom .spinner-icon {
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: solid 2px transparent;
  border-top-color: $loader-colour;
  border-left-color: $loader-colour;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  position: relative;
  overflow: hidden;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar,
.nprogress-custom-parent #nprogress-custom .spinner,
.nprogress-custom-parent #nprogress-custom .bar {
  position: absolute;
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
