@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/common";

.selector-h {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
}

.selector-h__option-fixed {
  margin-right: 20px;
}

.selector-h__list-wrapper {
  position: relative;
  height: 100%;
}

.selector-h__list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.selector-h__list-visible {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;

  &.--is-loading {
    visibility: hidden;
    animation: unset;
    pointer-events: none;
    touch-action: none;
  }

  &.--has-loaded {
    opacity: 1;
    animation: fadein 2s;
  }
}

.selector-h__option-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 36px;

  &:last-child {
    margin-right: 0;
  }

  &:only-child {
    margin-right: 0;
  }
}

.selector-h__option-wrapper-first {
  margin-left: 20px;
}

.selector-h__option {
  display: flex;
  align-items: center;
  height: 100%;
  color: colors.$color-grey-x-light;
  white-space: nowrap;
  cursor: pointer;

  &.--is-selected {
    color: colors.$color-white;
    border-bottom: 1px solid colors.$color-white;
  }

  &.--is-hidden {
    visibility: hidden;
    pointer-events: none;
    touch-action: none;
  }
}

.selector-h__option-text {
  width: 100%;
  text-align: center;
}

.selector-h__more-drop-down {
  display: flex;
}

.selector-h__more-text {
  svg {
    --svg-color: #{colors.$color-grey-x-light};

    position: relative;
    top: 1px;
    width: 11px;
    height: 11px;
    margin-left: 10px;
    transform: rotate(90deg);
    opacity: 1;
    transition: all 0.3s;
  }

  &.--is-open {
    svg {
      --svg-color: #{colors.$color-white};

      transform: rotate(270deg);
    }

    color: colors.$color-white;
  }

  &.--is-selected {
    svg {
      --svg-color: #{colors.$color-white};
    }
  }
}

.selector-h__more-drop-down-contents {
  position: absolute;
  top: 72px;
  right: 0;
  z-index: 20;
  min-width: 100%;
  pointer-events: none;
  touch-action: none;
}
