@use "@/sass/abstracts/common";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/loading-states";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "../../variables/font-sizes.module";

.episode-preview-large {
  display: flex;
  flex-direction: column;
  transform: scale(1);
  cursor: pointer;

  &.--is-loading {
    cursor: auto;
  }

  .episode-preview-large-hover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    align-items: none;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .episode-preview-large-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: transform 0.2s ease-out;
  }

  &.--enable-hover-scale {
    @include common.hover {
      .episode-preview-large-content {
        transform: scale(0.98);
        transition: transform 0.2s ease-out;
      }
    }
  }

  .icon-touch-area {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    pointer-events: all;

    * {
      pointer-events: none;
      touch-action: none;
    }

    .circular-background {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background: rgb(0 0 0 / 60%);
      border-radius: 50%;

      svg {
        position: relative;
        height: 12px;
      }
    }
  }

  .square-wrapper {
    width: 100%;
    margin-bottom: 3%;

    .square-component {
      padding-bottom: 100%;
      border-radius: 20px;

      &.--disable-background {
        background: none;
      }
    }
  }

  .info-wrapper {
    top: 0;
    display: flex;
    flex-direction: column;
    min-height: 40px;
    margin-top: 6px;

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: colors.$color-grey-x-light;

      .bullet {
        position: relative;
        top: 1px;
        padding: 0 7px;
      }

      .genre {
        &.--is-centered {
          text-align: center;
        }
      }
    }

    .title {
      &.--is-playing {
        color: colors.$color-blue;
      }
    }
  }
}

.episode-preview-large-dash-seperator {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 0 4px;
}
