@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/loading-states";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "@/sass/variables/font-sizes.module";

@mixin slate-content-width {
  width: 38%;
  max-width: 650px;

  @include responsive-vw.support-vw-tablets {
    width: 56%;
  }

  @include responsive-vw.support-vw-small-desktops {
    width: 44%;
  }
}

.slate-info {
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    text-align: center;
  }

  .description {
    max-width: 260px;
    margin-top: 4px;
    text-align: center;
  }

  @include responsive-vw.support-minimum-vw-tablets {
    display: none;
  }
}

.slate-info-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 60%;
  margin-top: 10px;
}

.slate-info-buttons-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.slate-info-desktop {
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  @include slate-content-width;

  .title {
    margin-right: 24px;

    &:not(.--description-is-hidden) {
      @include responsive-vw.support-vw-tablets {
        @include fonts.font-size(120);
      }
    }

    @media only screen and  (max-width: 780px) {
      @include fonts.font-size(120);
    }
  }

  .description-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
  }

  .description-loading {
    height: 100px;
  }

  @include responsive-vw.support-vw-mobiles {
    display: none;
  }
}

.slate-info-summary-desktop {
  display: flex;
  flex-direction: row;
  width: 100vw;

  .--extra-bottom-margin {
    margin-bottom: 40px;
  }
}

.slate-info-desktop-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 28px;

  .button-component,
  .button-component-loading {
    margin-right: 16px;
  }
}

.slate-title {
  display: inline-flex;
  align-items: center;
  min-width: 66%; // Loading state
  margin-bottom: 8px;

  &.--is-clickable {
    @include common.hover-reduce-opacity;

    cursor: pointer;
  }
}

.slate-information-info-icon {
  &:not(:first-child) {
    margin-left: 48px;
  }

  display: flex;
  align-items: center;

  --svg-color: #{colors.$color-white};

  @include common.hover-reduce-opacity;

  svg {
    width: 24px;
    height: 24px;
  }
}
