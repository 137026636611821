@use "sass:map";
@use "sass:math";

// -----------------------------------------------------------------------------
// Font scaling
// -----------------------------------------------------------------------------

// Ratio for font size based on view width. This dictactes at what ratio
// fonts increase/decrease in size based on the viewport width
$font-scaling-ratio-per-vw: 0.84vw;

// Base font size used for calculating font sizes as a ratio
$fs-base: 12;

// -----------------------------------------------------------------------------
// Font sizes
// -----------------------------------------------------------------------------

// FONT SIZE KEYS
$fs-keys: (
  40: 40,
  50: 50,
  60: 60,
  70: 70,
  80: 80,
  90: 90,
  100: 100,
  110: 110,
  120: 120,
  140: 140,
  160: 160,
);

// PX UNITLESS font sizes
$font-sizes-px-unitless: (
  40: 12,
  50: 14,
  60: 16,
  70: 18,
  80: 20,
  90: 22,
  100: 24,
  110: 26,
  120: 28,
  140: 32,
  160: 52
);

// PX font sizes (including px unit)
$font-sizes-px-fixed: ();

@each $key, $fs in $font-sizes-px-unitless {
  $font-sizes-px-fixed: map.set($font-sizes-px-fixed, $key, #{$fs + px});
}

// RESPONSIVE UNITLESS font sizes
// This is a ratio defined by the PX values above, and the base value. Eg: 22px / 12px = 1.8333
$font-sizes-responsive: ();

@each $key, $fs in $font-sizes-px-unitless {
  $font-sizes-responsive: map.set($font-sizes-responsive, $key, math.div($fs, $fs-base));
}

// -----------------------------------------------------------------------------
// Line heights
// -----------------------------------------------------------------------------

// RESPONSIVE RATIO for line heights, as ratio of font size
// Eg, line height for 60 text should be 1.6 * the font-size
$line-height-ratios: (
  20: 1.5,
  30: 1.5,
  40: 1.5,
  50: 1.5,
  60: 1.6,
  70: 1.6,
  80: 1.6,
  90: 1.3,
  100: 1.3,
  110: 1.3,
  120: 1.3,
  140: 1.3,
  160: 1,
);

// RESPONSIVE UNITLESS line heights
$line-heights-responsive: ();

@each $key, $fs-responsive in $font-sizes-responsive {
  $line-heights-responsive: map.set(
    $line-heights-responsive,
    $key,
    #{$fs-responsive * map.get($line-height-ratios, $key) }
  );
}

// RESPONSIVE EM line heights
$line-heights-em: ();

@each $key, $lh-responsive in $line-heights-responsive {
  $line-heights-em: map.set($line-heights-em, $key, #{#{$lh-responsive}em});
}

// RESPONSIVE REM line heights
$line-heights-rem: ();

@each $key, $lh-responsive in $line-heights-responsive {
  $line-heights-rem: map.set($line-heights-rem, $key, #{#{$lh-responsive}rem});
}

// PX line heights (including px unit)
$line-heights-px-fixed: ();

@each $key, $fs in $font-sizes-px-unitless {
  $line-heights-px-fixed: map.set(
    $line-heights-px-fixed,
    $key,
    #{#{$fs * map.get($line-height-ratios, $key)}px}
  );
}

// -----------------------------------------------------------------------------
// Loading states
// -----------------------------------------------------------------------------

// MIN WIDTHS FOR LOADING FONT GRADIENTS
// This specifies the minimum width for text in a loading state, at each size
$text-loading-min-width: (
  20: "80px",
  30: "80px",
  40: "80px",
  50: "80px",
  60: "80px",
  70: "80px",
  80: "80px",
  90: "80px",
  100: "80px",
  110: "80px",
  120: "80px",
  140: "100px",
  160: "120px"
);

// EXPORT TO JAVASCRIPT
@each $key, $fs-key in $fs-keys {
  :export {
    #{"var-fs-key-"}#{$fs-key}: $fs-key;
  }
}
