@use "@/sass/abstracts/common";
@use "@/sass/abstracts/layout";
@use "@/sass/variables/colors.module";

/**
* Component for creating Progress bar
*
* @component slider-container
* @device All
* @browserTest Chrome
*/
.slider-container {
  --slider-thumb-width: 9px;
  --slider-track-height: 3px;
  --slider-bottom-padding: 18px;

  position: relative;
  padding-top: var(--slider-bottom-padding);
  padding-bottom: var(--slider-bottom-padding);
  cursor: pointer;

  @include common.hover {
    .slider-thumb {
      visibility: visible !important;
    }
  }

  .slider {
    position: relative;
    width: 100%;
    height: var(--slider-track-height);
  }

  .slider-track {
    position: absolute;
    width: 100%;
    height: 100%;
    background: colors.$color-grey;
    border-radius: 2px;
  }

  .slider-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: colors.$color-white;
    border-radius: 2px;
  }

  .slider-thumb {
    position: absolute;
    bottom: calc((var(--slider-track-height) / 2) - (var(--slider-thumb-width) / 2));
    width: var(--slider-thumb-width);
    height: var(--slider-thumb-width);
    background: white;
    border-radius: 50%;
    visibility: hidden;
  }
}

// modifiers
.--is-vertical {
  &.slider-container {
    position: relative;
    top: unset;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-right: var(--slider-bottom-padding) !important;
    padding-left: var(--slider-bottom-padding) !important;
  }

  &.slider {
    width: var(--slider-track-height);
    height: 100%;
  }

  &.slider-track {
    width: 100%;
    height: 100%;
  }

  &.slider-progress {
    top: unset;
    bottom: 0;
    width: 100%;
    height: unset;
  }

  &.slider-thumb {
    bottom: unset;
    left: calc((var(--slider-track-height) / 2) - (var(--slider-thumb-width) / 2));
  }
}

.--is-disabled {
  &.slider-container {
    cursor: auto;

    .slider-thumb {
      visibility: hidden !important;
    }
  }
}
