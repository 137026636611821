@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "@/sass/variables/fonts.module" as font-variables;

$menuspacing: 8px;

@mixin support-bm-small-mobile-heights {
  @media only screen and (max-height: 620px) {
    @content;
  }
}

@mixin support-bm-small-mobiles {
  @include support-bm-small-mobile-heights {
    @content;
  }

  @include responsive-vw.support-vw-small-mobiles {
    @content;
  }
}

.logo-vertical-bar {
  width: 2px;
  height: 29px;
  margin-right: 14px;
  margin-left: 16px;
  background: colors.$color-white;
  border-radius: 2px;

  @include responsive-vw.support-vw-mobiles {
    height: 20px;
  }
}

.logo-icon-wrapper {
  position: relative;
  left: -8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  pointer-events: all;

  --svg-color: #{colors.$color-white};

  @include common.hover-reduce-opacity;

  svg {
    width: 30px;
  }
}

.logo-icon-wrapper-mobile {
  position: relative;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;

  --svg-color: #{colors.$color-white};

  svg {
    left: 0;
    width: 20px;
  }
}

// overlays
.burger-overlay-background {
  background: #{colors.$color-black};
  opacity: 0.95;
}

.burger-overlay-content {
  padding: 30px;
  overflow-x: hidden !important;
  overflow-y: auto !important;

  @include common.remove-scroll-bars;

  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
  }
}

.burger-genres-back-caret {
  margin-right: 20px;

  svg {
    position: relative;
    top: 2px;
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
  }
}

.burger-menu-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  .burger-menu-close-wrapper {
    position: relative;
    right: -8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;

    .burger-close {
      height: 100%;

      @include common.hover-reduce-opacity;

      svg {
        --svg-color: #{colors.$color-white};

        position: relative;
        top: 2px;
        width: 22px;
        height: 22px;
      }
    }
  }
}

// menu options
.burger-menu-options {
  display: flex;
  flex-direction: column;
  margin-bottom: calc($menuspacing * 4);

  @include support-bm-small-mobiles {
    margin-bottom: calc($menuspacing * 2);
  }

  .internal-link {
    padding: $menuspacing 0;

    @include support-bm-small-mobile-heights {
      @include fonts.font-size(70);

      padding: calc($menuspacing / 2) 0;
    }

    &.--is-disabled {
      opacity: 0.3;
    }

    &:not(.--is-disabled) {
      cursor: pointer;

      @include common.hover-reduce-opacity;
    }

    &.--is-selected {
      color: colors.$color-blue;
    }
  }

  .genres-internal-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .burger-option-caret {
    --svg-color: #{colors.$color-grey-x-light};

    width: 10px;
    height: 10px;

    @include support-bm-small-mobiles {
      width: 8px;
      height: 8px;
    }

    &.--is-selected {
      --svg-color: #{colors.$color-blue};
    }
  }

  .external-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include common.hover-reduce-opacity;

    .external-link-text {
      padding: $menuspacing 0;

      @include support-bm-small-mobile-heights {
        @include fonts.font-size(70);

        padding: calc($menuspacing / 2) 0;
      }
    }

    svg {
      position: relative;
      bottom: 2px;
      height: 21px;
    }
  }
}

// app links
.app-link {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 340px;
  margin-top: calc($menuspacing * 2);
  margin-bottom: calc($menuspacing * 2);
  transition: 0.2s ease-in-out;

  @include support-bm-small-mobile-heights {
    margin-top: calc($menuspacing * 1);
    margin-bottom: calc($menuspacing * 1);
  }

  @media only screen and (max-height: 680px) {
    width: 80%;
  }

  &:active {
    transform: scale(0.97);
  }

  .text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 40%;
    height: 100%;
    margin: 20px;
    padding: 0;

    // Fix issue where the text would be invisible on mobile safari
    @include common.hardware-acceleration;

    @include support-bm-small-mobiles {
      @include fonts.font-size(70);

      margin: 12px;
    }
  }

  .image {
    z-index: 0;
  }
}

// sign in footer
.burger-menu-footer {
  .user-section {
    display: flex;
    flex-direction: row;

    @include common.hover-reduce-opacity;

    align-items: center;
    margin-top: 16px;
    cursor: pointer;

    &.--is-selected {
      color: colors.$color-blue;
    }

    .user-image-wrapper {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }

    .user-name {
      position: relative;
      top: 1px;
    }
  }
}

// genres scroller
.burger-genres-scroller {
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
  font-family: font-variables.$font-stack-roc;

  @include common.remove-scroll-bars;
}

.burger-genres-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
  cursor: pointer;

  span {
    position: relative;
    top: -3px;
  }

  @include common.hover-reduce-opacity;
}

.burger-genre {
  @include common.hover-reduce-opacity;

  display: flex;
  width: 100%;
  margin-bottom: 12px;
  padding-left: 38px;
  cursor: pointer;

  &.--is-selected {
    color: colors.$color-blue;
  }

  .new-badge {
    margin-left: 12px;
  }
}
