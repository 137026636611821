// -----------------------------------------------------------------------------
// Colors
// -----------------------------------------------------------------------------

// Greyscale
$color-white: #ffffff;
$color-grey-x-light: #ffffffa6;
$color-grey-light: #85868f;
$color-grey-medium: #444445;
$color-grey: #282829;
$color-black-light: #212121;
$color-black-matte: #0f0f0f;
$color-black: #000000;

// Primary
$color-blue: #007aff;
$color-purple: #9747ff;

// EXPORT TO JAVASCRIPT
:export {
  var-white: $color-white;
  var-grey-x-light: $color-grey-x-light;
  var-grey-light: $color-grey-light;
  var-medium-grey: $color-grey-medium;
  var-grey: $color-grey;
  var-light-black: $color-black-light;
  var-matte-black: $color-black-matte;
  var-black: $color-black;
  var-blue: $color-blue;
  var-purple: $color-purple;
}
