@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/loading-states";
@use "@/sass/variables/breakpoints.module";
@use "@/sass/variables/font-sizes.module";
@use "./ShowInfo";
@use "./SlateOverlay";

@mixin slate-border-radius {
  &.--has-border-radius {
    border-radius: 12px;

    @include responsive-vw.support-vw-mobiles {
      border-radius: 8px;
    }
  }
}

.show-preview-slate__wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include slate-border-radius;
}

.show-preview-slate__component {
  position: relative;
  width: 100%;
  transform: scale(1);
  cursor: unset;

  &.--with-hover-scale {
    @include common.hover {
      .show-preview-slate__scalable-contents {
        transform: scale(0.98);
      }
    }
  }

  &.--is-clickable {
    cursor: pointer;
  }

  &.--is-loading {
    height: 100%;
  }
}

.show-preview-slate__scalable-contents {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  transform: translateZ(0);
  transition: transform 0.3s ease-out;
  pointer-events: all;

  &.--is-loading {
    visibility: none;
    pointer-events: none;
  }

  @include slate-border-radius;
}

.show-preview-slate {
  @include common.hardware-acceleration;
}
