@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/loading-states";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "../../variables/font-sizes.module";

/* Disable styleling temporarily until file is refactored */
/* stylelint-disable */

.episode-preview-sortable-helper {
  opacity: 0.6;
}

.episode-preview-list-wrapper {
  width: 100%;

  &.sortable {
    margin-bottom: 32px;
  }
}

.episode-preview {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px 16px;
  border-radius: 2px;
  cursor: pointer;

  &:not(.--is-loading) {
    @include common.hover-highlight-background;
    @include common.hover-reduce-opacity;
  }

  .number-icon,
  .sort-icon,
  .play-icon,
  .tick-icon {
    position: relative;
    flex: 0.06;
    min-width: 40px;
    max-width: 60px;
    color: colors.$color-grey-x-light;

    &.--is-playing {
      color: colors.$color-blue;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .sort-icon {
    position: relative;
    top: 2px;
    left: 1px;
    cursor: move;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .play-icon {
    --svg-color: #{colors.$color-blue};

    top: 2px;
  }

  &.--is-playing {
    background-color: colors.$color-black-light;
  }
}

.episode-preview-with-artwork {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;

  &.--extra-padding {
    padding: 10px 26px 20px;
  }

  &.--is-loading {
    cursor: auto;
  }

  &.--is-playing {
    @include common.hover-reduce-opacity(0.78);

    background-color: colors.$color-black-light;
  }

  &:not(.--is-loading) {
    &:not(.--is-playing) {
      @include common.hover-highlight-background;
    }
  }

  @include responsive-vw.support-vw-x-large-desktops {
    padding: 20px 20px 30px;
  }

  .episode-grip-handle {
    position: absolute;
    left: 14px;
    display: flex;
    align-items: center;
    cursor: move;

    svg {
      height: 14px;
    }
  }

  .episode-preview-artwork-wrapper {
    align-self: stretch;

    .image-wrapper {
      position: relative;
      margin-right: 16px;

      .episode-image-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: colors.$color-black;
        opacity: 0.2;
      }

      .episode-play-pause-icon {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        svg {
          height: 20px;
        }

        @include responsive-vw.support-vw-mobiles {
          svg {
            height: 16px;
          }
        }
      }
    }
  }

  .episode-preview-with-artwork-content {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: space-between;
    width: 100%;

    &:not(.--align-content-top) {
      align-items: center;
    }

    .menu-button {
      position: absolute;
      top: 8px;
      right: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      background: rgb(68 68 69 / 0%);
      border-radius: 100%;
      transform: rotate(90deg);
      transition: background-color 0.2s linear;

      --svg-color: #{colors.$color-white};

      &.--extra-padding {
        right: 24px;
      }

      svg {
        position: relative;
        left: 1px;
        height: 18px;
      }

      @include common.hover {
        --svg-color: #{colors.$color-white};

        background: colors.$color-grey-medium;
      }
    }

    .info {
      width: 100%;

      .episode-title {
        width: calc(100% - 60px);
        margin-bottom: 4px;
        font-weight: 700;
        display: flex;

        .prefix {
          color: colors.$color-grey-x-light;
        }

        .title-text {
          &.--is-playing {
            color: colors.$color-blue;
          }
        }

        .new-badge {
          margin-left: 12px;
        }
      }

      .description-text {
        color: colors.$color-grey-x-light;
      }

      .description-text .episode-description {
        color: colors.$color-white;
      }

      .bottom-info-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;

        .episode-duration {
          margin-right: 14px;
          color: colors.$color-grey-x-light;
        }

        .explicit-square {
          position: relative;
          top: -1px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 15px;
          height: 15px;
          color: colors.$color-black;
          font-weight: 700;
          background: colors.$color-grey-x-light;
          border-radius: 3px;

          --svg-color: #{colors.$color-black};

          svg {
            height: 8px;
          }
        }
      }
    }
  }

  .episode-preview-artwork {
    width: 80px;
    min-width: 80px;
    height: 80px;

    @include responsive-vw.support-vw-mobiles {
      width: 52px;
      min-width: 52px;
      height: 52px;
    }
  }
}

.episode-preview-summary {
  display: flex;
  flex-direction: row;
  width: 100%;

  .text {
    flex: 0.9;

    .title {
      padding-bottom: 4px;
      cursor: pointer;

      &.--is-playing {
        color: colors.$color-blue;
      }
    }

    .description {
      color: colors.$color-grey-x-light;
      cursor: pointer;

      &.--is-white {
        color: colors.$color-white;
      }
    }
  }

  .menu-button-wrapper {
    display: flex;
    flex: 0.1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    cursor: pointer;

    .menu-button {
      position: relative;
      right: -22px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;

      --svg-color: #{colors.$color-grey-x-light};

      svg {
        height: 12px;
      }

      @include common.hover {
        --svg-color: #{colors.$color-white};
      }
    }
  }
}