@use "@/sass/variables/colors.module";

// -----------------------------------------------------------------------------
// CSS variables
// -----------------------------------------------------------------------------

:root {
  // Color
  --theme-background: #{colors.$color-black};
  --svg-color: #{colors.$color-white};
}
