@use "@/sass/variables/breakpoints.module";

// -----------------------------------------------------------------------------
// Viewport width mixins/functions
// -----------------------------------------------------------------------------

@mixin support-vw-small-mobiles {
  @media only screen and (max-width: breakpoints.$bp-width-px-small-mobile) { @content; }

  @media only screen and (max-width: breakpoints.$bp-width-px-mobile) and (max-height: 740px) { @content; }
}

@mixin support-vw-mobiles {
  @media only screen and (max-width: breakpoints.$bp-width-px-mobile) { @content; }
}

@mixin support-vw-tablets {
  @media only screen
    and (min-width: breakpoints.$bp-width-px-mobile-as-lower-bound)
    and  (max-width: breakpoints.$bp-width-px-tablet) { @content; }
}

@mixin support-vw-small-desktops {
  @media only screen
    and (min-width: breakpoints.$bp-width-px-tablet-as-lower-bound)
    and (max-width: breakpoints.$bp-width-px-small-desktop) { @content; }
}

@mixin support-vw-medium-desktops {
  @media only screen
    and (min-width: breakpoints.$bp-width-px-small-desktop-as-lower-bound)
    and (max-width: breakpoints.$bp-width-px-medium-desktop) { @content; }
}

@mixin support-vw-large-desktops {
  @media only screen and (min-width: breakpoints.$bp-width-px-medium-desktop-as-lower-bound) { @content; }
}

@mixin support-vw-x-large-desktops {
  @media only screen and (min-width: breakpoints.$bp-width-px-large-desktop-as-lower-bound) { @content; }
}

// -----------------------------------------------------------------------------
// Target minimum viewport widths
// -----------------------------------------------------------------------------

@mixin support-minimum-vw-tablets {
  @media only screen and (min-width: breakpoints.$bp-width-px-mobile-as-lower-bound) { @content; }
}

@mixin support-minimum-vw-small-desktops {
  @media only screen and (min-width: breakpoints.$bp-width-px-tablet-as-lower-bound) { @content; }
}

@mixin support-minimum-vw-medium-desktops {
  @media only screen and (min-width: breakpoints.$bp-width-px-small-desktop-as-lower-bound) { @content; }
}

@mixin support-minimum-vw-large-desktops {
  @media only screen and (min-width: breakpoints.$bp-width-px-medium-desktop-as-lower-bound) { @content; }
}

@mixin support-minimum-vw-x-large-desktops {
  @media only screen and (min-width: breakpoints.$bp-width-px-large-desktop-as-lower-bound) { @content; }
}

@mixin support-minimum-vw-xx-large-desktops {
  @media only screen and (min-width: 3000px) { @content; }
}

// -----------------------------------------------------------------------------
// Target maximum viewport widths
// -----------------------------------------------------------------------------

@mixin support-maximum-vw-tablets {
  @media only screen and (max-width: breakpoints.$bp-width-px-tablet) { @content; }
}

@mixin support-maximum-vw-small-desktops {
  @media only screen and (max-width: breakpoints.$bp-width-px-small-desktop) { @content; }
}

@mixin support-maximum-vw-medium-desktops {
  @media only screen and (max-width: breakpoints.$bp-width-px-medium-desktop) { @content; }
}

// -----------------------------------------------------------------------------
// Target maximum viewport width based on size parameter
// -----------------------------------------------------------------------------

@mixin support-vw-with-max($size) {
  @if $size == "tablet" {
    @include support-maximum-vw-tablets { @content; }
  } @else if $size == "small-desktop" {
    @include support-maximum-vw-small-desktops { @content; }
  } @else if $size == "medium-desktop" {
    @include support-maximum-vw-medium-desktops { @content; }
  }
}

// -----------------------------------------------------------------------------
// Target minimum viewport width based on size parameter
// -----------------------------------------------------------------------------

@mixin support-vw-with-min($size) {
  @if $size == "tablet" {
    @include support-minimum-vw-tablets { @content; }
  } @else if $size == "small-desktop" {
    @include support-minimum-vw-small-desktops { @content; }
  } @else if $size == "medium-desktop" {
    @include support-minimum-vw-medium-desktops { @content; }
  } @else if $size == "large-desktop" {
    @include support-minimum-vw-large-desktops { @content; }
  } @else if $size == "x-large-desktop" {
    @include support-minimum-vw-x-large-desktops { @content; }
  }
}

// -----------------------------------------------------------------------------
// Only display on mobile/desktop
// -----------------------------------------------------------------------------

@mixin only-display-for-mobile-widths {
  @include support-minimum-vw-tablets {
    display: none !important;
  }
}

@mixin only-display-for-non-mobile-widths {
  @include support-vw-mobiles {
    display: none !important;
  }
}
