@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/loading-states";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "@/sass/variables/font-sizes.module";

.show-top-banner-desktop {
  @include responsive-vw.only-display-for-non-mobile-widths;
}

.channel-header-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;

  .image {
    min-width: 150px;
    height: 150px;
    border-radius: 15px;

    img {
      border-radius: 15px !important;
    }
  }
}

.season-selector-mobile {
  color: colors.$color-grey-x-light;
}

.dskt__channel-content-wrapper {
  @include responsive-vw.only-display-for-non-mobile-widths;
}

.channel-content-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.channel-seasons-selector-mobile {
  margin-bottom: 2px;
}

.episode-preview-cross-fade {
  overflow: hidden;
  border: 1px solid rgb(0 0 0 / 0%);
  transition: all 0.2s !important;

  &.--is-highlighted {
    background-color: colors.$color-black-light;
    border-left: 1px solid colors.$color-blue;
    transition: all 0.2s !important;
  }
}

.channel-episodes-wrapper {
  width: 100%;

  .episode-preview {
    padding: 20px;

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include responsive-vw.support-vw-tablets {
      padding: 12px;

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    @include responsive-vw.support-vw-small-desktops {
      padding: 12px;
    }

    @include responsive-vw.support-vw-medium-desktops {
      padding: 16px;
    }

    @include responsive-vw.support-vw-large-desktops {
      padding: 24px;
    }
  }
}

.desktop-channel-top-section {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  max-height: clamp(800px, 90vh, 1000px);

  .about {
    position: relative;
    display: flex;
    flex-basis: 28%;
    flex-direction: column;
    flex-shrink: 0;
    max-width: 480px;

    @include responsive-vw.support-maximum-vw-tablets {
      flex-basis: 47%;
    }

    @include responsive-vw.support-minimum-vw-small-desktops {
      flex-basis: 40%;
    }

    @include responsive-vw.support-vw-x-large-desktops {
      flex-basis: 33%;
      max-width: 550px;
    }

    .about-content {
      flex: 1;
      height: 100%;
      padding: 7px 0 50px;
      overflow: auto;

      @include common.remove-scroll-bars;

      &.--is-loading {
        overflow: hidden;
      }
    }
  }

  .about-spacer {
    flex-basis: 100px;

    @include responsive-vw.support-vw-small-desktops {
      flex-basis: 80px;
    }

    @include responsive-vw.support-vw-tablets {
      flex-basis: 50px;
    }
  }

  .episodes {
    position: relative;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1100px;

    .episodes-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 38px;
      margin-bottom: 12px;

      .episodes-title {
        margin-right: 42px;
      }

      .episodes-drop-down-wrapper {
        position: relative;
        top: -1px;
        width: 150px;
        color: colors.$color-grey-x-light;
      }
    }

    .episodes-content {
      flex: 1;
      height: 100%;
      padding-top: 8px;
      overflow-x: hidden;
      overflow-y: auto;
      touch-action: pan-y;

      @include common.custom-scroll-bar;

      &.--is-loading {
        overflow: hidden;
      }
    }
  }
}

.desktop-channel-bottom-section {
  width: 100%;
  margin-top: 64px;
}
