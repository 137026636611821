// -----------------------------------------------------------------------------
// Layout variables
// -----------------------------------------------------------------------------

// UNITLESS
// Header heights
$header-height-unitless-mobile: 80;
$header-height-unitless-desktop: 116;

// Footer heights
$footer-height-unitless-mobile: 62;
$footer-height-unitless-desktop: 82;

// Page padding
$page-padding-top-unitless-desktop: $header-height-unitless-desktop + 32;

// Add on alot of extra padding to allow for the currently playing track being large in size
$page-padding-bottom-unitless-desktop: $footer-height-unitless-desktop + 140;
$page-padding-top-unitless-mobile: $header-height-unitless-mobile;
$page-padding-bottom-unitless-mobile: $footer-height-unitless-mobile + 32;

// PX
// Header heights
$header-height-px-mobile: #{$header-height-unitless-mobile + px};
$header-height-px-desktop: #{$header-height-unitless-desktop + px};

// Footer heights
$footer-height-px-mobile: #{$footer-height-unitless-mobile + px};
$footer-height-px-desktop: #{$footer-height-unitless-desktop + px};

// Header padding
$header-top-bottom-padding-desktop: 32px;

// Page padding
$page-padding-top-px-desktop: #{$page-padding-top-unitless-desktop + px};
$page-padding-bottom-px-desktop: #{$page-padding-bottom-unitless-desktop + px};
$page-padding-top-px-mobile: #{$page-padding-top-unitless-mobile + px};
$page-padding-bottom-px-mobile: #{$page-padding-bottom-unitless-mobile + px};

// EXPORT TO JAVASCRIPT
:export {
  // Header heights
  var-header-height-desktop: $header-height-unitless-desktop;
  var-header-height-mobile: $header-height-unitless-mobile;

  // Footer heights
  var-footer-height-desktop: $footer-height-unitless-desktop;
  var-footer-height-mobile: $footer-height-unitless-mobile;

  // Page content padding
  var-page-padding-top-desktop: $page-padding-top-unitless-desktop;
  var-page-padding-bottom-desktop: $page-padding-bottom-unitless-desktop;
  var-page-padding-top-mobile: $page-padding-top-unitless-mobile;
  var-page-padding-bottom-mobile: $page-padding-bottom-unitless-mobile;
}
