.squareImageComponent {
  padding-bottom: 100%;
  aspect-ratio: 1;

  &.hasBorderRadius {
    border-radius: 8px;
  }
}

.topGradient {
  &.hasBorderRadius {
    border-radius: 8px;
  }
}

.bottomGradient {
  &.hasBorderRadius {
    border-radius: 8px;
  }
}
