@use "@/sass/abstracts/responsive-vw";

.shifted-hero-info {
  position: absolute;
  z-index: 2;
  display: flex !important;
  transform: translateY(-100%);
}

.shifted-hero-info-position {
  width: 100%;
  margin-bottom: 32px;

  @include responsive-vw.support-vw-mobiles {
    margin-bottom: 24px;
  }
}
