@use "@/sass/abstracts/common";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";

.privacy-policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px 0;

  @include responsive-vw.support-vw-mobiles {
    margin: 12px 0;
  }

  .header {
    margin-top: 8px;
    margin-bottom: 32px;
  }

  .section {
    margin-bottom: 32px;
  }

  .link {
    @include common.hover-reduce-opacity;

    color: colors.$color-blue;
    font-weight: 700;
    cursor: pointer;
  }
}
