// -----------------------------------------------------------------------------
// Sliding transition styles
// -----------------------------------------------------------------------------

// CSS Transition Groups
// Slide up 1s
.transition-translate-y-enter {
  transform: translateY(100%);
  opacity: 0;
}

.transition-translate-y-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.5s;
}

.transition-translate-y-exit {
  transform: translateY(0);
  opacity: 1;
}

.transition-translate-y-exit-active {
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.5s;
}

.transition-slide-from-left {
  transform: translateX(-100%);
  transition: all 0.3s ease-out;

  &.--is-open {
    transform: translateX(0);
    transition: all 0.3s ease-out;
  }
}

.transition-slide-from-right {
  transform: translateX(100%);
  transition: all 0.3s ease-out;

  &.--is-open {
    transform: translateX(0);
    transition: all 0.3s ease-out;
  }
}

.transition-slide-from-top {
  transform: translateY(-100%);
  transition: all 0.3s ease-out;

  &.--is-open {
    transform: translateY(0);
    transition: all 0.3s ease-out;
  }
}

.transition-slide-from-bottom {
  transform: translateY(calc(100% + 100px));
  transition: all 0.3s ease-out;

  &.--is-open {
    transform: translateY(0);
    transition: all 0.3s ease-out;
  }
}
