// -----------------------------------------------------------------------------
// Content padding variables
// -----------------------------------------------------------------------------

$content-padding-left-right: 5%;
$content-padding-left-right-tablet: 28px;
$content-padding-left-right-mobile: 26px;
$content-padding-left-right-small-mobile: 20px;

// EXPORT TO JAVASCRIPT
:export {
  var-left-right-padding-desktop: $content-padding-left-right;
  var-left-right-padding-tablet: $content-padding-left-right-tablet;
  var-left-right-padding-mobile: $content-padding-left-right-mobile;
  var-left-right-padding-small-mobile: $content-padding-left-right-small-mobile;
}
