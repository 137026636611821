@use "@/sass/abstracts/common";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";

.error-page-full {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.error-page {
  z-index: 59;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  align-self: center;
  max-width: 2000px;
  padding: 0 16px;
  overflow: auto;

  @include common.remove-scroll-bars;

  .error-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 0;
    width: 50%;
    height: 100%;
    margin-right: 2.5vw;

    .title {
      margin-bottom: 16px;
    }

    .info {
      margin-bottom: 16px;
    }

    .description {
      margin-bottom: 32px;
      color: colors.$color-grey-x-light;
    }

    .buttons {
      display: flex;
      flex-direction: row;

      .error-button {
        margin-right: 16px;
        color: colors.$color-white;
        background: colors.$color-blue;
      }
    }
  }

  .error-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    order: 1;
    width: 50%;
    height: 100%;
    max-height: 400px;
    margin-left: 2.5vw;

    svg {
      width: 500px;
      height: auto;
      max-height: 85vh;

      @include responsive-vw.support-vw-tablets {
        width: 300px;
      }

      @include responsive-vw.support-vw-mobiles {
        width: 200px;
      }
    }
  }

  @include responsive-vw.support-vw-mobiles {
    display: flex;
    flex-direction: column;
    margin-top: -80px;

    .error-information {
      align-items: center;
      justify-content: flex-start;
      order: 1;
      width: 100%;
      margin-top: 2.5vh;
      margin-right: 0;

      .title,
      .info,
      .description {
        text-align: center;
      }
    }

    .error-logo {
      align-items: center;
      justify-content: flex-end;
      order: 0;
      width: 100%;
      margin-bottom: 2.5vh;
      margin-left: 0;
    }
  }

  @include responsive-vw.support-vw-tablets {
    .error-information {
      width: 100%;
    }

    .error-logo {
      display: none;
    }
  }

  @include responsive-vw.support-vw-mobiles {
    @media only screen and (max-height: 700px) {
      margin-top: 0;

      .error-information {
        justify-content: center;
        margin-top: 0;
      }

      .error-logo {
        display: none;
      }
    }
  }

  @include responsive-vw.support-vw-small-mobiles {
    margin-top: 0;

    .error-information {
      justify-content: center;
      margin-top: 0;
    }

    .error-logo {
      display: none;
    }
  }
}
