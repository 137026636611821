@use "@/sass/variables/content-padding.module";

// -----------------------------------------------------------------------------
// Layout mixins/functions
// -----------------------------------------------------------------------------

@mixin layout-wrapper-horizontal {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  width: calc(100% - (content-padding.$content-padding-left-right * 2));

  &.--is-row {
    flex-direction: row;
  }

  &.--fill-height {
    height: 100%;
  }
}

@mixin layout-wrapper-horizontal-tablet {
  @include layout-wrapper-horizontal;

  width: calc(100% - (content-padding.$content-padding-left-right-tablet * 2));
}

@mixin layout-wrapper-horizontal-mobile {
  @include layout-wrapper-horizontal;

  width: calc(100% - (content-padding.$content-padding-left-right-mobile * 2));
}

@mixin layout-wrapper-horizontal-small-mobile {
  @include layout-wrapper-horizontal;

  width: calc(100% - (content-padding.$content-padding-left-right-small-mobile * 2));
}

@mixin layout-wrapper-vertical {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: auto;

  &.--is-row {
    flex-direction: row;
  }
}
