@use "@/sass/abstracts/responsive-vw";
@use "./Desktop/Desktop.scss";
@use "./Mobile/Mobile.scss";

.discover-rows-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include responsive-vw.support-vw-mobiles {
    margin-top: 64px;
  }
}
