@use "@/sass/abstracts/common";

.genres-with-slash__genre {
  &.--is-clickable {
    @include common.hover {
      text-decoration: underline;
      cursor: pointer;
    }

    @include common.hover-reduce-opacity;
  }

  &.--with-left-margin {
    margin-left: 4px;
  }

  &.--with-right-margin {
    margin-right: 4px;
  }
}

.genres-with-slash__static {
  width: 100%;
}
