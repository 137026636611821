@use "@/sass/abstracts/layout";

.header-bar {
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  pointer-events: none;
  touch-action: none;
}
