@use "sass:math";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/fonts";
@use "@/sass/variables/layout-dimensions.module";

$top-padding: #{math.div(layout-dimensions.$header-height-unitless-mobile, 3) + px};

.mob__genre-header-fixed {
  @include responsive-vw.only-display-for-mobile-widths;

  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: $top-padding;
  background: colors.$color-black;
}

.mob__genre-header {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.mob__genre-header-text {
  @include responsive-vw.support-vw-small-mobiles {
    @include fonts.font-size-with-loading(70);

    position: relative;
    top: 1px;
  }
}

.mob__genre-back-arrow {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  transform: rotate(180deg);

  svg {
    width: 16px;
    height: 16px;
  }
}

.mob__genre-selector-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 60px;
}

.mob__genre-selector {
  position: relative;
  bottom: -1px;
}

.mob__genre-selector-list {
  padding-left: 64px;
  animation: fadein 2s;

  &.--disable-padding {
    padding-left: 0;
  }

  &.--is-loading {
    visibility: hidden;
    animation: unset;
    pointer-events: none;
    touch-action: none;
  }
}

.mob__genre-selector-option {
  &:last-child {
    padding-right: 120px;
  }
}
