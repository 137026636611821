@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";

.search-header-burger {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.search-bar-wrapper {
  z-index: 10;
  width: calc(100% - 50px);
  height: 40px;
}

.search-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.search-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .search-genres-list {
    width: 100%;
  }
}

.desktop-search {
  @include responsive-vw.only-display-for-non-mobile-widths;

  flex: 1 !important;

  .desktop-search-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 64px;
    margin-bottom: 64px;

    .title-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .link {
        color: colors.$color-blue;
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
        touch-action: none;

        &.--is-active {
          opacity: 1;
          transition: opacity 0.3s;
          pointer-events: all;
          touch-action: auto;

          @include common.hover-reduce-opacity;
        }
      }
    }

    .title {
      padding-bottom: 16px;
      font-weight: 700;
    }

    &.--is-hidden {
      height: 0;
      margin: 0;
      opacity: 0;
      transition: all 0.2s;
    }
  }

  .desktop-search-bar {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    max-width: 800px;
    padding-bottom: 16px;
  }
}

.search-message-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: colors.$color-grey-x-light;

  .block-contents {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .light-text {
    color: colors.$color-white;
  }

  .search-symbol {
    --svg-color: #{colors.$color-grey};

    width: 60px;
    height: 60px;
    margin-bottom: 16px;
  }

  .broken-symbol {
    --svg-color: #{colors.$color-grey};

    width: 60px;
    height: 60px;
    margin-bottom: 16px;
  }

  .retry {
    margin-top: 16px;
  }
}

.search-episode-wrapper-grid {
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: repeat(auto-fill, 100%);
  width: 100%;

  @include responsive-vw.support-vw-tablets {
    grid-template-columns: repeat(auto-fill, calc(50% - 8px));
  }

  @include responsive-vw.support-vw-with-min("small-desktop") {
    grid-template-columns: repeat(auto-fill, calc(33.33% - 10.66px));
  }

  @include responsive-vw.support-vw-with-min("large-desktop") {
    grid-template-columns: repeat(auto-fill, calc(25% - 12px));
  }

  .search-episode-wrapper {
    width: 100%;
    padding: 16px;
    border-radius: 4px;

    &:not(.--is-loading) {
      @include common.hover-highlight-background;

      cursor: pointer;
    }

    &.--is-mobile {
      height: unset;
    }
  }
}

.search-prompt-wrapper {
  display: flex;
  flex: 1 !important;
  flex-direction: column;
  justify-content: center;
}

.search-prompt-component {
  margin-bottom: 7.5%;
}

.mobile-search {
  @include responsive-vw.only-display-for-mobile-widths;

  flex: 1 !important;
}
