@use "@/sass/abstracts/common";
@use "@/sass/abstracts/layout";
@use "@/sass/variables/colors.module";
@use "@/sass/variables/layout-dimensions.module";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/abstracts/transitions";

.header-mobile {
  &:not(.--display-on-desktop) {
    @include responsive-vw.only-display-for-mobile-widths;
  }

  @include transitions.make-content-filterable;

  min-height: layout-dimensions.$header-height-px-mobile;
  pointer-events: all;
  touch-action: auto;

  .header-mobile-content {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .content {
      width: 100%;
    }

    .left {
      display: flex;
      flex: 1 1 25%;
      flex-direction: row;
      justify-content: flex-start;
    }

    .center {
      display: flex;
      flex: 1 1 50%;
      flex-direction: row;
      justify-content: center;
    }

    .right {
      display: flex;
      flex: 1 1 25%;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

.header-back-cross {
  position: relative;
  left: -9px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  pointer-events: all;

  @include common.hover-reduce-opacity;

  svg {
    --svg-color: #{colors.$color-white};

    position: relative;
    top: 50%;
    width: 22px;
    height: 22px;
    transform: translateY(-50%) rotate(45deg);
  }
}

.header-back-cross-circle {
  width: 36px;
  height: 36px;
  background: colors.$color-grey;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;

  svg {
    --svg-color: #{colors.$color-white};

    position: relative;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 22px;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
}

// Common features used in HeaderBarMobile across the app
.header-back-caret {
  padding: 16px 16px 16px 2px;
  cursor: pointer;
  pointer-events: all;

  @include common.hover-reduce-opacity;

  svg {
    --svg-color: #{colors.$color-white};

    position: relative;
    top: 50%;
    width: 18px;
    height: 18px;
    transform: translateY(-50%) rotate(180deg);
  }

  &.down {
    svg {
      transform: translateY(-50%) rotate(90deg);
    }
  }
}
