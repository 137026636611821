@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/loading-states";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";

.nav-options-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 20px;
  margin: 30px 8px 16px;
  padding: 0;
  list-style-type: none;

  &.--bold-text {
    font-weight: 700;
  }

  .option {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    color: colors.$color-grey-x-light;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s ease-out;

    @include common.hover-reduce-opacity;

    &.--is-loading {
      width: 64px;
      height: 24px;
      margin: 0 8px;
      border-radius: 12px;

      @include loading-states.loading-gradient-background-horizontal;
    }

    &.--is-selected {
      color: colors.$color-white;
    }

    @include responsive-vw.support-vw-tablets {
      padding: 0 16px;

      .bullet {
        margin-top: -8px;
      }
    }

    @include responsive-vw.support-vw-small-desktops {
      padding: 0 20px;

      .bullet {
        margin-top: -8px;
      }
    }

    @include responsive-vw.support-vw-medium-desktops {
      padding: 0 24px;

      .bullet {
        margin-top: -10px;
      }
    }

    @include responsive-vw.support-vw-large-desktops {
      padding: 0 30px;

      .bullet {
        margin-top: -12px;
      }
    }
  }

  &.--is-loading {
    .option {
      cursor: auto;
    }
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
