@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/responsive-vw";

// -----------------------------------------------------------------------------
// App wide page styles
// -----------------------------------------------------------------------------

html {
  scrollbar-color: rgb(133 134 143 / 55%) rgb(0 0 0 / 0%);

  ::-webkit-scrollbar {
    width: 0.5em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 rgb(0 0 0 / 0%);
  }

  ::-webkit-scrollbar-thumb {
    background-color: colors.$color-grey;
    border-radius: 5px;

    @include common.hover {
      background-color: rgb(133 134 143 / 55%);
    }
  }
}

body {
  background: var(--theme-background);

  // Stop SVGs wobbling
  svg {
    transform: translateZ(0);
  }
}

.logo-beta-text {
  position: relative;
  top: 2px;
}

.tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  margin-right: auto;
  margin-left: auto;
  padding: 24px;
  text-align: center;
  background: rgb(21 21 21 / 80%);
  border: 1px solid colors.$color-blue;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 75%);
  transform: translate(-50%, -50%);
  opacity: 0;
  backdrop-filter: blur(5px);
  pointer-events: none;
  touch-action: none;
}
