@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/common";

.scrolling-selector-h {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
}

.scrolling-selector-h__padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.scrolling-selector-h__option-fixed {
  margin-right: 20px;
}

.scrolling-selector-h__list-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.scrolling-selector-h__list {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: auto;

  @include common.remove-scroll-bars;
}

.scrolling-selector-h__option-wrapper {
  display: flex;
  align-items: flex-end;
  height: 100%;

  &.--disable-padding {
    margin-left: 0;
  }
}

.scrolling-selector-h__option-wrapper-first {
  margin-left: 20px;
}

.scrolling-selector-h__option {
  display: flex;
  height: 100%;
  padding: 0 14px;
  cursor: pointer;

  &.--is-selected {
    color: colors.$color-white;
    border-bottom: 1px solid colors.$color-white;
  }
}

.scrolling-selector-h__option-text {
  display: flex;
  align-self: flex-end;
  padding-bottom: 16px;
  color: colors.$color-grey-x-light;
  white-space: nowrap;
}
