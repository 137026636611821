@use "./RowHeader";
@use "@/sass/abstracts/common";
@use "@/sass/abstracts/responsive-vw";

.show-row {
  width: 100%;
  margin-bottom: clamp(120px, 8vh, 250px);

  @include responsive-vw.support-vw-mobiles {
    margin-bottom: 88px;
  }

  @include responsive-vw.support-vw-small-mobiles {
    margin-bottom: 50px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.--is-disabled {
    pointer-events: none;
  }
}

.show-row-impression-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.show-row-slate-list {
  @include common.show-row;
  @include common.remove-scroll-bars;

  grid-auto-flow: column;
  padding: 0 5%;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; /* Mobile safari */

  @include responsive-vw.support-vw-tablets {
    padding: 0 28px;
  }

  @include responsive-vw.support-vw-mobiles {
    padding: 0 26px;
  }

  @include responsive-vw.support-vw-small-mobiles {
    padding: 0 20px;
  }

  &:not(.--disable-scroll-snap) {
    scroll-snap-type: x mandatory;
    scroll-padding: 5%;

    @include responsive-vw.support-vw-tablets {
      scroll-padding: 28px;
    }

    @include responsive-vw.support-vw-mobiles {
      scroll-padding: 26px;
    }

    @include responsive-vw.support-vw-small-mobiles {
      scroll-padding: 20px;
    }
  }

  &.--disable-scroll {
    overflow-x: hidden;
  }
}
