@use "@/sass/abstracts/layout";

.scrolling-wrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.--is-centered {
    justify-content: center;
  }
}
