@use "@/sass/abstracts/common";
@use "@/sass/abstracts/loading-states";

$transition-out-duration: 0.2;

.imageContainer {
  position: relative;
  width: 100%;
  overflow: hidden;

  &.showBackground {
    @include loading-states.loading-gradient-background-vertical("loading");
  }

  &.isClickable {
    cursor: pointer;

    @include common.hover-reduce-opacity;
  }

  &:not(.disableBorderRadius) {
    border-radius: inherit;

    * {
      overflow: hidden;
      border-radius: inherit;
      transform: translateZ(0); // Make sure blur works with border radius on safari
    }
  }
}

.image {
  @include common.hardware-acceleration;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  filter: blur(12px);
  transition: opacity #{#{$transition-out-duration}s} ease-in, filter #{#{$transition-out-duration}s} ease-in;
  aspect-ratio: inherit;

  &.lowResLoaded {
    opacity: 1;
  }

  &.highResLoaded {
    filter: blur(0);
  }

  img {
    position: relative;
  }
}
