@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/variables/font-sizes.module" as fs;
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/loading-states";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/breakpoints.module";
@use "@/sass/variables/colors.module";
@use "@/sass/variables/font-sizes.module";

.show-row-header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  @include responsive-vw.support-vw-mobiles {
    margin-bottom: 12px;
  }
}

.show-row-title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.show-row-title {
  align-items: center;
  width: fit-content;
  margin: 0;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 12px;

    @include responsive-vw.support-vw-mobiles {
      width: 12px;
      height: 12px;
      margin-left: 8px;
    }
  }

  &.--is-clickable {
    cursor: pointer;

    @include common.hover-reduce-opacity;

    @include common.hover {
      text-decoration: underline;
    }
  }

  &.--is-loading {
    width: 20%;
    max-width: 250px;
  }
}

.genre-header-badge {
  margin-bottom: 8px;
}

.show-row-description {
  margin-top: -2px;
  color: colors.$color-grey-x-light;

  &.--is-loading {
    width: 40%;
    max-width: 400px;
  }

  @include responsive-vw.support-vw-mobiles {
    @include fonts.font-size-with-loading(60);
  }
}

.show-row-controls {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  opacity: 0;
  transition: opacity 0.4s;

  &.--is-active {
    opacity: 1;
    transition: opacity 0.4s;
  }
}

.show-row-caret-control {
  display: flex;
  flex-direction: row;

  --svg-color: #{colors.$color-grey};

  align-items: center;
  width: 24px;
  height: 100%;

  svg {
    width: 16px;
    height: 16px;
  }

  &.--faces-left {
    svg {
      transform: rotate(180deg);
    }
  }

  &.--is-active {
    --svg-color: #{colors.$color-white};

    cursor: pointer;

    @include common.hover-reduce-opacity;
  }

  &:last-child {
    justify-content: flex-end;
  }

  @include responsive-vw.support-vw-tablets {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  @include responsive-vw.support-vw-small-desktops {
    width: 27px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  @include responsive-vw.support-vw-medium-desktops {
    width: 30px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  @include responsive-vw.support-vw-large-desktops {
    width: 30px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
