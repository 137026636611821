@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/variables/colors.module";

.drop-down {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  padding: 6px 10px;
  color: colors.$color-white;
  border: 1px solid colors.$color-grey-x-light;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;

  .drop-down__current-value {
    position: relative;
    top: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-weight: 700;
    cursor: pointer;
  }

  .drop-down__current-value-text {
    overflow: hidden;
    white-space: nowrap;
  }

  svg {
    --svg-color: #{colors.$color-white};

    width: 14px;
    height: 14px;
    margin-bottom: 1px;
    margin-left: 20px;
    transform: rotate(90deg);
    opacity: 1;
    transition: all 0.3s;
  }

  &.--is-open {
    svg {
      top: 0;
      transform: rotate(270deg);
      transition: all 0.3s;
    }
  }

  &.--is-disabled {
    opacity: 0.3;

    .current-value {
      cursor: unset;
    }
  }

  &:not(.--is-disabled) {
    @include common.hover-highlight-background(colors.$color-grey);
  }

  &.--is-loading {
    .current-value {
      cursor: unset;
    }

    svg {
      opacity: 0;
    }
  }
}

.drop-down__options-wrapper {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 20;
  width: 100%;
  max-height: 300px;
  pointer-events: none;
  touch-action: none;
}

.drop-down__options {
  width: 100%;
  max-height: 300px;
  overflow: auto;
  background: colors.$color-black-matte;
  border: 1px solid colors.$color-grey-x-light;
  border-radius: 6px;
  cursor: auto;
  opacity: 0;
  transition: all 0.3s;
  pointer-events: none;
  touch-action: none;

  &.--is-open {
    opacity: 1;
    pointer-events: auto;
    touch-action: auto;
  }
}

.drop-down__option {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  color: colors.$color-white;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.2s;

  @include common.hover-highlight-background(colors.$color-grey);

  &.--is-selectable {
    color: colors.$color-grey-x-light;

    &.--is-selected {
      color: colors.$color-white;
    }
  }

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 8px;
  }
}
