@use "@/sass/abstracts/common";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/transitions";
@use "@/sass/variables/colors.module";
@use "@/sass/variables/layout-dimensions.module";

.page-header {
  position: relative;
  z-index: 55;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  height: layout-dimensions.$header-height-px-mobile;

  @include transitions.make-content-filterable;
}

.page-content {
  position: relative;
  z-index: 10;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  background: colors.$color-black;
  -webkit-overflow-scrolling: touch; /* Mobile safari */
  overscroll-behavior: none;

  @include common.custom-scroll-bar;
  @include transitions.make-content-filterable;

  &.--disable-hover * {
    pointer-events: none !important;
  }
}

.page-footer {
  z-index: 65;
  flex: 0 0;

  @include transitions.make-content-filterable;
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
