@use "@/sass/abstracts/common";

.tileGrain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/static/images/grain.png");
  background-size: contain;
  border-radius: inherit;
  opacity: 0;
  filter: brightness(0.6);
  transition: opacity 1s linear;

  @include common.hardware-acceleration;

  &.isLoaded {
    opacity: 0.6;
  }
}
