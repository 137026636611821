@use "@/sass/variables/colors.module";

.mob__genre-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-top: 24px;
}

.mob__genre-info-loading-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mob__genre-info-loading-text-line {
  width: 100%;

  &:last-child {
    width: 50%;
  }
}

.mob__genre-description {
  color: colors.$color-white;
  text-align: center;
}

.mob__genre-shows-count {
  min-width: 80px;
  margin-top: 16px;
  color: colors.$color-grey-x-light;
  text-align: center;
}
