@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/common";

.show-preview-slate__info {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
}

.show-preview-slate__title-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  &.--is-centered {
    align-items: center;
  }
}

.show-preview-slate__title {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @include common.hover-reduce-opacity;

  &.--is-loading {
    position: relative;
    width: 66%;
  }

  &.--is-centered {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.show-preview-slate__title-placeholder {
  visibility: hidden;
}

.show-preview-slate__format {
  color: colors.$color-grey-x-light;

  &.--is-loading {
    width: 33%;
  }
}
