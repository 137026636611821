@use "@/sass/abstracts/common";
@use "@/sass/variables/colors.module";

.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 20px;

  .slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: colors.$color-grey;
    cursor: pointer;
    transition: 0.4s;
  }

  &.--is-disabled {
    opacity: 0.5;

    .slider {
      cursor: auto;
    }
  }

  &:not(.--is-disabled) {
    @include common.hover-reduce-opacity;
  }

  .slider::before {
    position: absolute;
    bottom: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
    transition: 0.4s;
    content: "";
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  input:checked + .slider {
    background-color: colors.$color-blue;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px colors.$color-blue;
  }

  input:checked + .slider::before {
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }

  .slider.round::before {
    border-radius: 50%;
  }
}
