@use "@/sass/abstracts/layout";
@use "@/sass/variables/colors.module";

.button-cta-play-pause-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  color: colors.$color-grey-x-light;

  svg {
    width: 16px;
    height: 16px;
    margin-bottom: 3px;

    --svg-color: #{colors.$color-black};
  }
}
