@use "@/sass/abstracts/common";
@use "@/sass/abstracts/layout";

.music-player-controls {
  position: relative;
  top: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .controls-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s linear;

    --svg-color: white;

    @include common.hover-reduce-opacity;

    &.--is-invisible {
      display: none;
    }

    &.--is-disabled {
      opacity: 0.5;
      pointer-events: none;
      touch-action: none;
    }

    svg {
      height: 100%;
    }
  }

  .play-button {
    width: 36px;
    height: 36px;
    margin: 0 70px;
  }
}
