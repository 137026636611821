// -----------------------------------------------------------------------------
// View width breakpoints - upper bounds
// THIS SHOULD BE THE SOURCE OF TRUTH FOR ALL BREAKPOINTS, JS INCLUDED!
// -----------------------------------------------------------------------------

// Extra small mobile
$bp-width-px-unitless-small-mobile: 380;
$bp-width-px-small-mobile: #{$bp-width-px-unitless-small-mobile + 0px};
$bp-width-px-small-mobile-as-lower-bound: #{$bp-width-px-unitless-small-mobile + 1px};
$bp-height-px-unitless-small-mobile: 700;
$bp-height-px-small-mobile: #{$bp-height-px-unitless-small-mobile + 0px};

// Mobile
$bp-width-px-unitless-mobile: 700;
$bp-width-px-mobile: #{$bp-width-px-unitless-mobile + 0px};
$bp-width-px-mobile-as-lower-bound: #{$bp-width-px-unitless-mobile + 1px};

// Tablet
$bp-width-px-unitless-tablet: 1024;
$bp-width-px-tablet: #{$bp-width-px-unitless-tablet + 0px};
$bp-width-px-tablet-as-lower-bound: #{$bp-width-px-unitless-tablet + 1px};

// Small desktop
$bp-width-px-unitless-small-desktop: 1440;
$bp-width-px-small-desktop: #{$bp-width-px-unitless-small-desktop + 0px};
$bp-width-px-small-desktop-as-lower-bound: #{$bp-width-px-unitless-small-desktop + 1px};

// Medium desktop
$bp-width-px-unitless-medium-desktop: 1920;
$bp-width-px-medium-desktop: #{$bp-width-px-unitless-medium-desktop + 0px};
$bp-width-px-medium-desktop-as-lower-bound: #{$bp-width-px-unitless-medium-desktop + 1px};

// Large desktop
$bp-width-px-unitless-large-desktop: 2150;
$bp-width-px-large-desktop: #{$bp-width-px-unitless-large-desktop + 0px};
$bp-width-px-large-desktop-as-lower-bound: #{$bp-width-px-unitless-large-desktop + 1px};

// EXPORT TO JAVASCRIPT
:export {
  var-bp-width-small-mobile: $bp-width-px-unitless-small-mobile;
  var-bp-width-mobile: $bp-width-px-unitless-mobile;
  var-bp-width-tablet: $bp-width-px-unitless-tablet;
  var-bp-width-small-desktop: $bp-width-px-unitless-small-desktop;
  var-bp-width-medium-desktop: $bp-width-px-unitless-medium-desktop;
  var-bp-width-large-desktop: $bp-width-px-unitless-large-desktop;
}
