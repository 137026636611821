@use "@/sass/abstracts/layout";
@use "@/sass/variables/colors.module";
@use "../MusicPlayerBar";

/**
* Component for adjusting desktop player volume
*
* @component volume-wrapper
* @device Desktop
*/
.player-bar-desktop {
  .volume-icon {
    display: flex;
    flex-direction: column;

    @include MusicPlayerBar.is-faded;

    align-items: center;
    justify-content: center;
    height: 20px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .volume-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 40px;
    cursor: pointer;

    &:not(.--is-muted) {
      left: 3px;
    }

    &.--is-muted {
      svg {
        height: 16px;
        transform: scale(0.98);
      }
    }
  }

  .volume-icon-click {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .desktop-volume-bar-wrapper {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    opacity: 0;
    transition: opacity 0.15s linear;
    pointer-events: none;
    touch-action: none;

    &.--is-visible {
      opacity: 1;
      pointer-events: all;
      touch-action: auto;
    }
  }

  .desktop-volume-bar {
    height: 240px;
    margin-bottom: 80px;
    padding: 24px 0;
    background: colors.$color-black-matte;
    border-radius: 12px;
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 75%);
  }

  .desktop-volume-progress {
    background: colors.$color-blue;
  }
}

// Mixin
@mixin is-faded {
  transition: opacity 0.2s ease-out;

  &.--is-faded {
    opacity: 0.5;
    transition: opacity 0.2s ease-out;
  }
}
