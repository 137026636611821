@use "@/sass/abstracts/common";
@use "@/sass/abstracts/layout";
@use "@/sass/variables/colors.module";
@use "@/sass/variables/fonts.module" as font-variables;

/* Scroller */

.scroller {
  width: 100%;
  margin: 0;
  padding: 128px 0;
  overflow-y: auto;
  list-style-type: none;
  -webkit-overflow-scrolling: touch; /* Mobile safari */

  @include common.remove-scroll-bars;

  // Label
  p {
    padding: 16px 0;
    color: colors.$color-grey-x-light;
    font: 0.9rem font-variables.$font-stack-maison-neue;
    text-align: center;
  }

  // Item
  li {
    padding: 8px 0;
    color: colors.$color-white;
    font: 1.2rem font-variables.$font-stack-maison-neue;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
  }

  &.--is-closable {
    padding: 100px 0 180px;
  }
}

.scroller-close-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  cursor: pointer;

  svg {
    position: relative;
    top: 3px;
    left: 1px;
    width: 20px;
    height: 20px;
  }

  .scroller-close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-bottom: 16px;
    background: colors.$color-grey;
    border-radius: 50%;
  }
}
