@use "@/sass/abstracts/common";
@use "@/sass/variables/colors.module";

.slate-overlay__info-section {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4% 8% 8% 12%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s linear;

  &.--is-visible {
    opacity: 1;

    @include common.hover {
      opacity: 0.75;
    }
  }
}

.slate-overlay__play {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 2%;
  margin-left: -4%;
  padding-right: 6%;
  padding-left: 4%;
  transition: opacity 0.2s linear;

  svg {
    width: 18px;
    height: 18px;
  }
}

.slate-overlay__details {
  width: 100%;
  overflow: hidden;
}

.slate-overlay__title {
  width: 100%;
  overflow: hidden;
  color: colors.$color-white;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.slate-overlay__genres {
  width: 100%;
  color: colors.$color-grey-x-light;
}
