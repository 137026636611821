@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/variables/font-sizes.module" as fs;
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/loading-states";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";

.button-circular {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 56px;
  min-height: 56px;
  color: colors.$color-black;
  font-weight: 700;
  background: colors.$color-white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;
  pointer-events: auto;
  touch-action: auto;

  svg {
    width: 16px;
    height: 16px;
  }

  &.--is-disabled {
    cursor: auto;
    opacity: 0.55;
    transition: opacity 0.3s;
  }

  &.--is-loading {
    @include loading-states.loading-gradient-background-horizontal;

    cursor: auto;
  }

  &:not(.--is-disabled) {
    &:not(.--is-loading) {
      @include common.hover-reduce-opacity;
    }
  }

  @include responsive-vw.support-vw-small-mobiles {
    @include fonts.font-size-with-loading(40);

    width: 42px;
    height: 42px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.button-circular-contents {
  position: relative;
  top: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @include responsive-vw.support-vw-small-mobiles {
    top: 0;
  }
}
