@use "@/sass/abstracts/normalise";

// -----------------------------------------------------------------------------
// Our custom normalisation styles
// -----------------------------------------------------------------------------

// Our custom normalise styles
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;

  @include normalise.prevent-select-highlight;
}

ol,
ul,
li {
  padding: 0;
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

html {
  @include normalise.prevent-overflow;

  position: fixed;
  width: 100%;
  height: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

body {
  @include normalise.prevent-overflow;
  @include normalise.prevent-refresh;

  margin: 0;
}

/* stylelint-disable-next-line */
#__next {
  @include normalise.prevent-overflow;
}

.app {
  @include normalise.prevent-overflow;
}

textarea,
input[type="text"],
input[type="search"],
input[type="button"],
input[type="submit"],
input[type="password"] {
  background: none;
  border: none;
  border-radius: none;
  appearance: none !important;
}

// Remove background styles on autofill passwords
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 0s 50000s;
}

input { user-select: text; } // Ensure that inputs can be clicked on in safari ios

button {
  padding: 0;
  color: inherit;
  font: inherit;
  background: none;
  border: none;
  outline: inherit;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }
}
