@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/common";

.gradient-component-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: none;
  touch-action: none;
}

.gradient-component {
  width: 100%;
  height: 100%;
  pointer-events: none;
  touch-action: none;
}
