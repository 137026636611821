@use "@/sass/abstracts/common";

@mixin image-loader-child-common {
  overflow: hidden;
  visibility: unset;

  @include common.hardware-acceleration;

  &.--is-visible {
    visibility: visible;
  }
}

.image-loader-child-low-res {
  @include image-loader-child-common;

  &.--is-blurred {
    filter: blur(2px);
  }

  &.--is-blurred-heavy {
    filter: blur(16px);
  }
}

.image-loader-child-high-res {
  @include image-loader-child-common;
}
