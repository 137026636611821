@use "@/sass/abstracts/common";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/transitions";
@use "@/sass/variables/colors.module";
@use "@/sass/variables/layout-dimensions.module";
@use "@/sass/variables/font-sizes.module" as fs;

.queue-mobile {
  width: 100%;
  min-height: 100%;
  padding-top: layout-dimensions.$page-padding-top-px-mobile;
  overflow-y: auto;

  &.queue-background {
    background: colors.$color-black-matte;
  }

}

.queue-overlay {
  overflow-y: hidden;
  transform: translateX(100%);
  transition: all 0.25s ease-out;

  &.--is-open {
    transform: translateX(0);
    transition: all 0.25s ease-out;
  }
}

.queue-overlay-desktop.queue-overlay-desktop {
  position: absolute;
  top: unset;
  right: 0;
  bottom: 0;
  left: unset;
  width: 400px;
  height: 70%;
  max-height: 700px;
  outline: none;

  &.--is-closed {
    height: 0 !important;
  }
}

.queue-overlay-desktop-background {
  background: colors.$color-black-matte;
}

.queue-overlay-desktop-content {
  @include transitions.make-content-filterable;

  overflow-y: auto;

  .close-button {
    position: absolute;
    right: 32px;
    cursor: pointer;

    @include common.hover-reduce-opacity;

    svg {
      height: 20px;

      @include common.hover-reduce-opacity;
    }
  }
}

.queue-desktop {
  width: 100%;
  min-height: 100%;
  padding-top: layout-dimensions.$page-padding-top-px-mobile;
  overflow-y: auto;
}

.queue-desktop-header-cross {
  top: -2px;
  left: 9px;
}

.currently-playing-title {
  width: 100%;
  margin-bottom: 16px;
  padding: 0 32px;
}

.queue-heading-title {
  padding: 0 32px;
  cursor: pointer;

  @include common.hover-reduce-opacity;
}

.currently-playing-episode {
  width: 100%;
  margin-bottom: 32px;
}

.queue-heading-with-link {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 0 16px;

  .title {
    flex: 1 1 50%;
    padding-left: 32px;
  }

  .queue-nav-link {
    display: flex;
    flex: 1 1 50%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 32px;
    color: colors.$color-blue;
    cursor: pointer;

    @include common.hover-reduce-opacity;

    svg {
      position: relative;
      top: -1px;
      width: 13px;
      height: 13px;
      margin-left: 6px;

      --svg-color: #{colors.$color-blue};
    }
  }
}

.queue-episode-preview {
  @include fonts.font-size-with-loading(60, "fixed");
  @include common.hover-highlight-background;

  .episode-preview {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  &.more-padding {
    padding: 16px 32px !important;
  }
}
