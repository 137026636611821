@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/abstracts/transitions";
@use "@/sass/variables/layout-dimensions.module";
@use "@/sass/variables/fonts.module" as font-variables;

.dskt__header-bar {
  @include responsive-vw.only-display-for-non-mobile-widths;

  position: absolute;
  width: 100%;
  padding: layout-dimensions.$header-top-bottom-padding-desktop 0;
}

.desktop-header-search-wrapper {
  width: 75%;
}

.desktop-menu-wrapper-perm {
  position: absolute;
  top: 0;
  z-index: 65;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: layout-dimensions.$header-height-px-desktop;
  pointer-events: none;
  touch-action: none;

  @include transitions.make-content-filterable;
}

.desktop-full-page-overlay {
  z-index: 59 !important;
}

.burger-menu-wrapper-perm {
  @include transitions.make-content-filterable;

  position: absolute;
  top: 0;
  z-index: 65;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: layout-dimensions.$header-height-px-mobile;
  transition: transform 0.2s;
  pointer-events: none;
}

.header-desktop {
  @include responsive-vw.only-display-for-non-mobile-widths;

  min-height: layout-dimensions.$header-height-px-mobile;
  font-family: font-variables.$font-stack-roc;

  .header-desktop-content {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: all;
    touch-action: auto;

    .content {
      width: 100%;
    }

    .left {
      display: flex;
      flex: 1 1 25%;
      flex-direction: row;
      justify-content: flex-start;
    }

    .center {
      display: flex;
      flex: 1 1 50%;
      flex-direction: row;
      justify-content: center;
    }

    .right {
      display: flex;
      flex: 1 1 25%;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
